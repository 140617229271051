.PublicDeveloperDetails .HeroSectionOrganisationImage {
  width: 100%;
  height: 360px;
  background-position: center;
  background-size: cover;
}

.PublicDeveloperDetails .HeroSectionOrganisationLogo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: var(--color-light-grey);
  /* 78px being half of the logo max-height */
  margin-bottom: calc(var(--spacing-ll) - 78px);
}

.PublicDeveloperDetails .HeroSectionOrganisationLogo > div {
  background-color: var(--color-white);
  transform: translateY(-50%);
}

.PublicDeveloperDetails .NoHeroSectionOrganisationLogo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: var(--spacing-2xl);
  margin-bottom: var(--spacing-m);
}

.PublicDeveloperDetails .NoHeroSectionOrganisationLogo > div {
  background-color: var(--color-white);
  transform: none;
}

.PublicDeveloperDetails .PreviewButtonsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: calc(var(--spacing-ll) - (136px));
  background-color: var(--color-light-grey);
}

.PublicDeveloperDetails .NoHeroSectionPreviewButtonsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: calc(-1 * var(--spacing-ll));
  padding-top: calc(var(--spacing-ll) - var(--spacing-m));
  background-color: var(--color-light-grey);
}
.PublicDeveloperDetails .NoHeroSectionNoDeveloperLogoPreviewButtonsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: calc(var(--spacing-ll) - var(--spacing-m));
  margin-bottom: calc(var(--spacing-ml) - var(--spacing-m));
  background-color: var(--color-light-grey);
}

.PublicDeveloperDetails .PublicDeveloperDetailsPageTemplate .NoHeroSectionNoOrganisationLogoDisplayName {
  padding-top: var(--spacing-ml);
}

.PublicDeveloperDetails .PreviewButtonsContainer button:first-child,
.PublicDeveloperDetails .NoHeroSectionPreviewButtonsContainer button:first-child,
.PublicDeveloperDetails .NoHeroSectionNoDeveloperLogoPreviewButtonsContainer button:first-child {
  /*have to use the same css variable as the button left padding because secondary buttons have transparent background color*/
  margin-left: calc(-1 * var(--spacing-l));
}

.PublicDeveloperDetails .PublicDeveloperDetailsPageTemplate {
  padding: 0 var(--spacing-4xl) var(--spacing-4xl) var(--spacing-4xl);
  background-color: var(--color-light-grey);
}

.PublicDeveloperDetails .PublicDeveloperDetailsPageTemplate .OurProjectsSection {
  margin-top: var(--spacing-4xl);
}

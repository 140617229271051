.ProfileMenuWrapper {
  position: relative;
}

.ProfileMenuContainer {
  position: absolute;
  top: var(--spacing-s);
  right: 0;
  width: 250px;
  z-index: 1;
  background: var(--color-white);
  box-shadow: 0 0 var(--box-shadow-spread) var(--color-box-shadow);
  padding: var(--spacing-s) 0;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

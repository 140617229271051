.ContentSection {
  height: 100%;
  padding: var(--spacing-2xl) var(--spacing-xl) var(--spacing-xl) var(--spacing-2xl);
  background-color: var(--color-white);
}

.ContentSection h2 {
  margin-bottom: var(--spacing-ml);
}

.ContentSection .body1 {
  white-space: pre-line;
}

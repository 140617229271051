.ActivityIntroduction {
  padding: 0 var(--spacing-4xl);
}

.ActivityIntroduction ul {
  padding-left: var(--spacing-ml);
  margin: var(--spacing-sl) 0 var(--spacing-xs);
}

.ActivityIntroduction ul li {
  margin: var(--spacing-s) 0;
}

.ActivityIntroduction a {
  text-decoration: underline;
}

.ActivityIntroduction .Verifiers {
  display: flex;
  align-items: center;
}

.ActivityIntroduction .VerifierDetails {
  display: flex;
  align-items: center;
  column-gap: var(--spacing-s);
  padding-right: var(--spacing-ml);
}

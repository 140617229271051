.ActivityDiscussionTab {
  --filters-mid-offset: var(--spacing-ll);
}

.ActivityDiscussionTab .DiscussionHeader {
   display: flex;
   justify-content: space-between;
   align-items: center;
}

.ActivityDiscussionTab .DiscussionHeader .ProfileMenuWrapper {
  z-index: 50;
}

.DiscussionTabFilters {
  width: 100%;
  text-align: right;
  z-index: 10;
}

.DiscussionTable .Toggle {
  margin-top: calc(0px - var(--filters-mid-offset));
}

.DiscussionTable .BtnLink {
  margin-top: calc(0px - var(--filters-mid-offset));
}





.DiscussionTabFilters>div {
  display: inline-block;
  min-width: 225px;
}

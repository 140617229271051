.RegisterInterestFirstStepSubheader {
  margin-bottom: var(--spacing-ll);
}

.RegisterInterestSecondStepSubheader {
  text-align: center;
  margin-bottom: var(--spacing-ll);
}

.RegisterInterestThirdStepSubheader {
  margin-bottom: var(--spacing-ll);
}

.RegisterInterestSecondStepSubheader > h3 {
  margin-bottom: var(--spacing-s);
}

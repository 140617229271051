.DiscussionTable .rdg {
  grid-template-columns: 0px repeat(8, auto)!important;
  overflow: visible;
}

.DiscussionTable .rdg-row .rdg-cell:nth-child(2) {
  color: var(--color-grey);
  font-family: Space Grotesk;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
}

.DiscussionTable .rdg-row .rdg-cell:nth-child(1) {
  z-index: 2;
  overflow: visible;
  margin: 0px;
  padding: 0px;
  pointer-events: none;
}

.DiscussionTable .rdg-row .rdg-cell:nth-child(5),
.DiscussionTable .rdg-row .rdg-cell:nth-child(6),
.DiscussionTable .rdg-row .rdg-cell:nth-child(7),
.DiscussionTable .rdg-row .rdg-cell:nth-child(8)
{
  text-align: center;
}

.DiscussionTable .rdg-row .rdg-cell:nth-child(5),
.DiscussionTable .rdg-row .rdg-cell:nth-child(6),
.DiscussionTable .rdg-row .rdg-cell:nth-child(8) 
{
  color: var(--color-grey-40);
  /* Desktop/Body 2 - 14px */
  font-family: Space Grotesk;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}


.DiscussionTable .rdg-row .rdg-cell:nth-child(9) {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: visible;
}

.DiscussionTable .rdg {
  contain: none!important;
  overflow: visible;
  content-visibility: unset;
}

.DiscussionTable.hidden {
  display: none;
}

.ResolvedIcon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.DiscussionContainer {
  width: 600px;
}

.DiscussionContainer .DiscussionCard {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: inherit;
  padding: var(--spacing-ml);
  background: var(--color-white);
  box-shadow: 0 0 30px rgba(44, 48, 59, 0.03);
  max-height: 600px;
  overflow-y: scroll;
  z-index: 5;
}
.DiscussionContainer .DiscussionCard.Hidden {
  display: none;
}
.DiscussionContainer .DiscussionCard.VisibleOverflow {
  overflow-y: visible;
}

.BadgeWrapper {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  padding: var(--spacing-sl) var(--spacing-ml);
  margin-right: var(--spacing-m);
  margin-top: var(--spacing-m);
  background-color: var(--color-light-grey);
}

.BadgeWrapper .BadgeLabel {
  padding-left: var(--spacing-s);
  font-weight: 700;
  font-size: var(--font-size-body2);
  line-height: var(--line-height-body1);
  color: var(--color-grey);
  overflow: hidden;
  white-space: nowrap;
}

.BadgeWrapper .BadgeLogo {
  height: 24px;
}

.TotalSequestrationContainer {
  padding: var(--spacing-m) var(--spacing-m);
  background-color: var(--color-white);
  color: var(--color-grey);

  height: 136px;
  width: 100%;
  display: flex;
  flex: 1;
  align-items: flex-start;
}

.TotalSequestrationContainer .TotalSequestrationGraph {
  display: grid;
  grid-template-columns: 40% 30% 30%;
  grid-template-rows: 24px 24px 56px;

  flex: 3;
  height: 104px;
  margin-right: var(--spacing-m);
}

.TotalSequestrationContainer .TotalSequestrationGraph.Small div:nth-child(3),
.TotalSequestrationContainer .TotalSequestrationGraph.Medium div:nth-child(n + 2),
.TotalSequestrationContainer .TotalSequestrationGraph.Large div:nth-child(n + 1) {
  background-color: var(--color-green);
}

.TotalSequestrationContainer .TotalSequestrationGraph .GraphSection {
  border-top: var(--spacing-xxs) solid var(--color-white);
  border-right: var(--spacing-xxs) solid var(--color-white);
  background-color: var(--color-green-20);
}
.TotalSequestrationContainer .TotalSequestrationGraph .GraphSection.Small {
  grid-column: 1 / 2;
  grid-row: 3 / 4;
}

.TotalSequestrationContainer .TotalSequestrationGraph .GraphSection.Medium {
  grid-column: 1 / 3;
  grid-row: 2 / 4;
}

.TotalSequestrationContainer .TotalSequestrationGraph .GraphSection.Large {
  grid-column: 1 / 4;
  grid-row: 1 / 4;
  border-top: none;
  border-right: none;
}

.TotalSequestrationContainer .TotalSequestrationHeaderArea {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 7;
}

.TotalSequestrationContainer .TotalSequestrationHeaderArea > h2 {
  margin-bottom: var(--spacing-s);
}

.TotalSequestrationContainer .TotalSequestrationHeaderArea p.body3 {
  color: var(--color-grey-40);
  margin-top: auto;
}

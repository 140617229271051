.Checkbox:hover .Checkbox-checked .Checkbox-inner {
  background-color: var(--color-blue-80);
}

.Checkbox-input:focus-visible + .Checkbox-inner {
  outline-offset: var(--spacing-xs);
  outline: 1px dashed var(--color-grey-40);
}

.CheckboxWrapper {
  display: flex;
}
.CheckboxWrapper > label {
  cursor: pointer;
}
.Checkbox {
  display: flex;
  align-items: center;
  position: relative;
}
.Checkbox:hover .Checkbox-inner {
  border-color: var(--color-grey-20);
}
.Checkbox-inner {
  position: relative;
  top: 0;
  left: 0;
  display: inline-block;
  width: 16px;
  height: 16px;
  border: 2px solid var(--color-grey-40);
  background-color: var(--color-white);
}
.Checkbox-inner:after {
  transform: rotate(45deg);
  position: absolute;
  left: 4px;
  top: 1px;
  display: table;
  width: 5px;
  height: 8px;
  border: 2px solid var(--color-white);
  border-top: 0;
  border-left: 0;
  content: " ";
}
.Checkbox-input {
  position: absolute;
  inset: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}
/* Checked state */
.Checkbox-checked:hover .Checkbox-inner {
  border-color: var(--color-blue-80);
  background-color: var(--color-blue-80);
}
.Checkbox-checked .Checkbox-inner {
  border-color: var(--color-blue);
  background-color: var(--color-blue);
}

.PublicBadgeDetails .PublicBadgeDetailsPageTemplate {
  padding: 0 var(--spacing-4xl) var(--spacing-4xl) var(--spacing-4xl);
  background-color: var(--color-light-grey);
}

.PublicBadgeDetails .PublicBadgeDetailsPageTemplate .LogoAndButtons {
  margin-top: var(--spacing-ll);
}

.PublicBadgeDetails .PublicBadgeDetailsPageTemplate .LogoAndButtons .Column > div:first-child {
  justify-content: flex-start;
}
.BackToProjectButton > button {
  padding: 0;
  justify-content: flex-start;
}

.PublicBadgeDetails .PublicBadgeDetailsPageTemplate .LogoAndButtons .Column > div:last-child {
  justify-content: flex-end;
}
.ContactUsButton {
  display: flex;
}

.PublicBadgeDetails .PublicBadgeDetailsPageTemplate .LogoAndButtons .LogoContainer {
  height: auto;
  width: auto;
  border: none;
}

.PublicBadgeDetails .PublicBadgeDetailsPageTemplate .LogoAndButtons .LogoContainer > img {
  height: 80px;
}

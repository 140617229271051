.RadioButtonGroup .Label {
  margin-bottom: var(--spacing-sl);
}

.RadioButtonGroup .TooltipChild {
  margin-left: var(--spacing-s);
}

.Radio-wrapper-checked:hover .Radio-inner {
  border-color: var(--color-blue-60);
}
.Radio-group-review .Radio-wrapper-checked:hover .Radio-inner {
  border-color: var(--color-green-60);
}

.Radio-wrapper-checked:hover .Radio-inner:after {
  background-color: var(--color-blue-60);
}
.Radio-group-review .Radio-wrapper-checked:hover .Radio-inner:after {
  background-color: var(--color-green-60);
}

.Radio-wrapper-disabled:hover .Radio-inner {
  border-color: var(--color-grey-40);
}

.Radio-wrapper-disabled:hover .Radio-inner:after {
  background-color: var(--color-grey-40);
}

.Radio-input:focus + .Radio-inner {
  box-shadow: none;
}

.Radio-input:focus-visible + .Radio-inner {
  box-shadow: none;
  outline: 1px dashed var(--color-grey-40);
  outline-offset: var(--spacing-xs);
}

.Radio:not([class*="Radio-checked"]) .Radio-inner {
  border: 2px solid var(--color-grey-40);
}

.Radio-wrapper:not([class*="Radio-wrapper-checked"]):not([class*="Radio-wrapper-disabled"]):hover .Radio-inner {
  border: 2px solid var(--color-grey-20);
}

.Radio-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #000000d9;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
  display: inline-block;
  font-size: 0;
}
.Radio-group-vertical.Radio-group-vertical {
  flex-direction: column;
  gap: var(--spacing-s);
}
.Radio-group {
  display: inline-flex;
  gap: var(--spacing-ll);
}
.Radio-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #000000d9;
  font-size: var(--font-size-body2);
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
  display: inline-flex;
  align-items: baseline;
}
.Radio-wrapper:not([class*="Radio-wrapper-disabled"]) {
  cursor: pointer;
}
.Radio {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  position: relative;
  top: 0.2em;
  display: inline-block;
}
.Radio:not([class*="Radio-disabled"]) {
  cursor: pointer;
}
.Radio-input {
  position: absolute;
  inset: 0;
  z-index: 1;
  opacity: 0;
}
.Radio:not([class*="Radio-disabled"]) .Radio-input {
  cursor: pointer;
}
.Radio-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  background-color: var(--color-white);
  border: 2px solid var(--color-blue);
  border-radius: 50%;
}
.Radio-group-review .Radio-inner {
  border: 2px solid var(--color-green);
}
.Radio-disabled .Radio-inner {
  border-color: var(--color-grey-40);
}
.Radio-checked:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid var(--color-blue);
  border-radius: 50%;
  visibility: hidden;
  content: "";
}
.Radio-group-review .Radio-checked:after {
  border: 1px solid var(--color-green);
}

.Radio-disabled:after {
  border-color: var(--color-grey-40);
}
.Radio-inner:after {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 16px;
  height: 16px;
  margin-top: -8px;
  margin-left: -8px;
  background-color: var(--color-blue);
  border-top: 0;
  border-left: 0;
  border-radius: 16px;
  transform: scale(0);
  opacity: 0;
  content: " ";
}
.Radio-group-review .Radio-inner:after {
  background-color: var(--color-green);
}
.Radio-disabled .Radio-inner:after {
  background-color: var(--color-grey-40);
}
.Radio-checked .Radio-inner:after {
  transform: scale(0.5);
  opacity: 1;
}
span.Radio + * {
  padding-right: var(--spacing-s);
  padding-left: var(--spacing-s);
}
.Radio-wrapper:after {
  display: inline-block;
  width: 0;
  overflow: hidden;
  content: "\a0";
}
.RadioButtonGroup_disabled label {
  color: var(--color-grey-40);
}

.DeveloperProjectCardWithTabsTemplate {
  min-height: 100vh;
  display: flex;
  background-color: var(--color-light-grey);
}

.DeveloperProjectCardWithTabsTemplate .TemplateTabs {
  background-color: var(--color-light-grey);
}

.DeveloperProjectCardWithTabsTemplate .TemplateTabs.TabsContent {
  padding: 0;
}

.DeveloperProjectCardWithTabsTemplate .TemplateTabs.TabsContent .ProjectTabSection {
  background-color: var(--color-white);
  padding: var(--spacing-2xl);
  margin-bottom: var(--spacing-ml);
  box-shadow: 0 0 var(--box-shadow-spread) var(--color-box-shadow);
}

.DeveloperProjectCardWithTabsTemplate .DeveloperProjectCardWithTabsTemplateContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 1024px;
  margin-right: auto;
  margin-left: auto;
}

.DeveloperProjectCardWithTabsTemplateContainer .HeaderContainer {
  display: flex;
  justify-content: space-between;
  margin-top: var(--spacing-l);
}

.DeveloperProjectCardWithTabsTemplateContainer .DeveloperInfo {
  display: flex;
  align-items: center;
  gap: var(--spacing-s);
}

/* For the media queries we are counting in the LeftNavigation width 104px  */
@media only screen and (min-width: 768px) {
  .DeveloperProjectCardWithTabsTemplateContainer {
    padding: 0 var(--spacing-ll) 0 calc(var(--spacing-ll) + 104px);
  }
}

@media only screen and (min-width: 1024px) {
  .DeveloperProjectCardWithTabsTemplateContainer {
    padding: 0 var(--spacing-2xl) 0 calc(var(--spacing-2xl) + 104px);
  }
}

@media only screen and (min-width: 1440px) {
  .DeveloperProjectCardWithTabsTemplateContainer {
    padding: 0 var(--spacing-4xl) 0 calc(var(--spacing-4xl) + 104px);
    max-width: 1864px; /* calc(1600px + 104px + (var(--spacing-h-2xl) * 2)) - this is 1600px + left nav bar 104px + padding on each side */
  }
}

/* Removing margin if screen width is 80px larger on each side than the max-width set of 1600px + the left navigation width 104px */
@media only screen and (min-width: 1864px) {
  .DeveloperProjectCardWithTabsTemplateContainer {
    /* Centering the content in the middle of the screen counting in LeftNavigation */
    padding: 0 0 0 104px;
    max-width: 1704px; /* 1600px + left nav bar 104px */
  }
}

.DeveloperProjectCardWithTabsTemplate .DeveloperProjectCardWithTabsTemplateNavigation {
  position: fixed;
  left: 0;
}

.DeveloperProjectCardWithTabsTemplate .HeaderContent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.DeveloperProjectCardWithTabsTemplate .ProjectVerifierAndCode {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--spacing-m);
}

.DeveloperProjectCardWithTabsTemplateContainer .ProjectActivityNotificationContent {
  width: 100%;
  display: flex;
  flex-direction: row;
  padding-top: var(--spacing-xxs);
}

.DeveloperProjectCardWithTabsTemplateContainer .ProjectActivityNotificationContent .BtnLink {
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
  display: flex;
  font-weight: 700;
  color: var(--color-blue);
  text-decoration: underline;
  margin-left: var(--spacing-xs);
}

.PublicCodeDetails .HeroSectionOrganisationImage {
  width: 100%;
  height: 360px;
  background-position: center;
  background-size: cover;
}

.PublicCodeDetails .HeroSectionOrganisationLogo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: var(--color-light-grey);
  /* 78px being half of the logo max-height */
  margin-bottom: calc(var(--spacing-ll) - 78px);
}

.PublicCodeDetails .HeroSectionOrganisationLogo > div {
  background-color: var(--color-white);
  transform: translateY(-50%);
}

.PublicCodeDetails .NoHeroSectionOrganisationLogo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: var(--spacing-2xl);
  margin-bottom: var(--spacing-m);
}

.PublicCodeDetails .NoHeroSectionOrganisationLogo > div {
  background-color: var(--color-white);
  transform: none;
}

.PublicCodeDetails .PreviewButtonsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: calc(var(--spacing-ll) - (136px));
  background-color: var(--color-light-grey);
}

.PublicCodeDetails .NoHeroSectionPreviewButtonsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: calc(-1 * var(--spacing-ll));
  padding-top: calc(var(--spacing-ll) - var(--spacing-m));
  background-color: var(--color-light-grey);
}

.PublicCodeDetails .NoHeroSectionNoCodeLogoPreviewButtonsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: calc(var(--spacing-ll) - var(--spacing-m));
  margin-bottom: calc(var(--spacing-ml) - var(--spacing-m));
  background-color: var(--color-light-grey);
}

.PublicCodeDetails .PreviewButtonsContainer button:first-child,
.PublicCodeDetails .NoHeroSectionPreviewButtonsContainer button:first-child,
.PublicCodecDeveloperDetails .NoHeroSectionNoCodeLogoPreviewButtonsContainer button:first-child {
  /*have to use the same css variable as the button left padding because secondary buttons have transparent background color*/
  margin-left: calc(-1 * var(--spacing-l));
}

.PublicCodeDetails .HeroSectionSubtitle {
  display: flex;
  justify-content: center;
}

.PublicCodeDetails .HeroSectionSubtitle p {
  max-width: 60vw;
}

.PublicCodeDetails .HeroSectionRegistry a {
  text-decoration: underline;
  color: var(--color-blue);
}

.PublicCodeDetails .PublicCodeDetailsPageTemplate {
  padding: 0 var(--spacing-4xl) var(--spacing-4xl) var(--spacing-4xl);
  background-color: var(--color-light-grey);
}

.PublicCodeDetails .CodeStatusKPIContainer,
.PublicCodeDetails .ProjectTypesSectionContainer {
  height: 100%;
  background-color: var(--color-white);
  padding: var(--spacing-2xl) var(--spacing-2xl);
}

.PublicCodeDetails .CoreCarbonPrinciplesEmergingSection {
  padding: var(--spacing-l) var(--spacing-3xl) var(--spacing-ml) var(--spacing-2xl);
  background-color: var(--color-white);
}

.PublicCodeDetails .CoreCarbonPrinciplesEmergingSection h2 {
  margin-bottom: var(--spacing-ml);
}

.PublicCodeDetails .ProjectTypesContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: var(--spacing-m);
}

.PublicCodeDetails .CoreCarbonPrinciplesEmergingBanner {
  display: flex;
  align-items: center;
  gap: var(--spacing-m);
  padding: var(--spacing-ml);
  background-color: var(--color-grey-20);
  border-radius: 2px;
}

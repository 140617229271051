.AboutUsSection {
  padding: var(--spacing-2xl) var(--spacing-3xl) var(--spacing-5xl) var(--spacing-2xl);
  background-color: var(--color-white);
}

.AboutUsSection > h2 {
  margin-bottom: var(--spacing-ll);
}

.AboutUsSection > img {
  max-width: 100%;
  height: 100%;
  max-height: 488px;
  margin-bottom: var(--spacing-ll);
}

.AboutUsSection .body1 {
  white-space: pre-line;
}

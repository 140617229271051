.ContactUsFirstStepSubheader {
  margin-bottom: var(--spacing-ll);
}

.ContactUsFirstStepSubheader .DeveloperPhoneNumber {
  font-weight: bold;
}

.ContactUsSecondStepSubheader {
  text-align: center;
  margin-bottom: var(--spacing-ll);
}

.ContactUsThirdStepSubheader {
  margin-bottom: var(--spacing-ll);
}

.ContactUsSecondStepSubheader > h3 {
  margin-bottom: var(--spacing-s);
}

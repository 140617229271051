.SocialMediaSection .UnclickableIcons,
.SocialMediaSection .ClickableIcons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: var(--spacing-m);
}

.SocialMediaSection .ClickableIcons a {
  width: 24px;
  height: 24px;
}

.SocialMediaSection .ClickableIcons a:hover path {
  stroke: var(--color-blue-60);
}

.ActivityReviewWorkflowWrapper {
  width: 100%;
  height: 240px;
  padding: var(--spacing-s) 0 var(--spacing-s) var(--spacing-s);
  background-color: var(--color-white);
  position: relative;
}

.ActivityReviewWorkflowWrapper .ActivityReviewWorkflow {
  width: 100%;
  height: 100%;
  column-gap: var(--spacing-ml);
  display: grid;
  grid-template-columns: min(40%, 280px) 1fr;
}

.ActivityReviewWorkflowWrapper .ActivityReviewWorkflow .ActivitiesWrapper {
  width: 100%;
  padding-right: var(--spacing-ml);
}

.ActivityReviewWorkflowWrapper .ActivityReviewWorkflow .ActivitiesWrapper .CompleteActivityMessage {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: var(--spacing-s);
  position: relative;
  top: var(--spacing-ll);
  padding-bottom: var(--spacing-ml);
}

.ActivityReviewWorkflowWrapper .ActivityReviewWorkflow .ActivitiesWrapper .Activities {
  display: flex;
  column-gap: var(--spacing-ml);
}

.ActivityReviewWorkflowWrapper .ActivityReviewWorkflow .ActivitiesWrapper .Activities .ProgressIndicator {
  padding-right: var(--spacing-m);
  width: 100%;
}

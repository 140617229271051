.RiskContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: var(--spacing-l);
  height: 100%;
  width: 100%;
  padding: var(--spacing-l);
  background-color: var(--color-white);
}

.RiskContainer .GaugeSection {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.RiskContainer .RateSection {
  display: flex;
  flex-direction: row;
  column-gap: var(--spacing-s);
  align-items: baseline;
}

.RiskContainer h2 {
  width: min-content;
}
.RiskContainer h3 {
  color: var(--color-blue);
  font-size: 14px;
}

.Toast {
  position: fixed;
  z-index: 1010;
  width: 100%;
  pointer-events: none;
}

.Toast-notice {
  text-align: center;
  margin-top: var(--spacing-s);
}

.Toast-notice-content {
  display: inline-block;
  padding: 0 var(--spacing-m);
  color: #fff;
  pointer-events: all;
}

.Toast-notice-success .Toast-notice-content {
  background-color: var(--color-green);
}

.Toast-notice-error .Toast-notice-content {
  background-color: var(--color-red);
}

.Toast-notice-warning .Toast-notice-content {
  background-color: var(--color-grey-60);
}

.Toast-notice-content svg path {
  fill: #fff;
}

.Toast-notice-with-icon {
  display: flex;
  gap: var(--spacing-s);
  align-items: center;
  height: var(--spacing-xl);
}

.Toast-notice.Toast-move-up-appear {
  opacity: 0;
}

.Toast-notice.Toast-move-up-appear.Toast-move-up-appear-active {
  animation: 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  animation-name: MessageMoveIn;
  animation-delay: 0s;
  animation-fill-mode: forwards;
  opacity: 0;
}

.Toast-notice.Toast-move-up-leave.Toast-move-up-leave-active {
  animation: 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  animation-name: MessageMoveOut;
  animation-delay: 0s;
  animation-fill-mode: forwards;
  opacity: 1;
}

@keyframes MessageMoveIn {
  0% {
    margin-top: -56px; /* 48px height + 8px margin-top */
    opacity: 0;
  }
  to {
    margin-top: var(--spacing-s);
    opacity: 1;
  }
}

@keyframes MessageMoveOut {
  0% {
    margin-top: var(--spacing-s);
    opacity: 1;
  }
  to {
    margin-top: -56px; /* 48px height + 8px margin-top */
    opacity: 0;
  }
}

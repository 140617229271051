.HorizontalProjectCards {
  height: 598px;
  width: 100%;
  padding: var(--spacing-xl);
  background-color: var(--color-white);
}

.HorizontalProjectCards .HorizontalProjectCardsHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.HorizontalProjectCards .CardsSection {
  display: flex;
  position: relative;
  flex-wrap: nowrap;
  column-gap: var(--spacing-ml);
  overflow-x: scroll;
  scroll-behavior: smooth;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.HorizontalProjectCards .CardsSection::-webkit-scrollbar {
  display: none;
}

.HorizontalProjectCards .ArrowLayer {
  position: relative;
}

.HorizontalProjectCards .ArrowLayer .CircleArrowLeftDarkIcon {
  position: absolute;
  z-index: 1;
  top: 136px;
  left: 16px;
  cursor: pointer;
}

.HorizontalProjectCards .ArrowLayer .CircleArrowRightDarkIcon {
  position: absolute;
  z-index: 1;
  top: 136px;
  right: 16px;
  cursor: pointer;
}

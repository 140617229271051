.WorkflowWrapper {
  width: 100%;
  height: 100%;
  min-height: 208px;
  padding: var(--spacing-s) 0 var(--spacing-s) var(--spacing-s);
  background-color: var(--color-white);
  position: relative;
}
.WorkflowWrapper.AssetManager {
  padding: 0;
}

.WorkflowWrapper .Workflow {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  column-gap: var(--spacing-ml);
  overflow-x: hidden;
  scroll-behavior: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.WorkflowWrapper .Workflow::-webkit-scrollbar {
  display: none;
}
.WorkflowWrapper.AssetManager .Workflow {
  column-gap: 0;
}

.WorkflowWrapper .Workflow .WorkflowStatus {
  min-width: 40%;
}

.WorkflowWrapper.AssetManager .Workflow .ActivitiesWrapper {
  padding: var(--spacing-l);
}

.WorkflowWrapper .Workflow .ActivitiesWrapper.Full {
  min-width: calc(60% - var(--spacing-ml));
  padding-right: var(--spacing-s);
}

.WorkflowWrapper .Workflow .ActivitiesWrapper .ActivitiesHeader {
  margin-top: var(--spacing-m);
}
.WorkflowWrapper.AssetManager .Workflow .ActivitiesWrapper .ActivitiesHeader {
  margin-top: 0;
}

.WorkflowWrapper .Workflow .ActivitiesWrapper .Activities {
  margin-top: var(--spacing-m);
  display: flex;
  column-gap: var(--spacing-s);
}

.WorkflowWrapper .Workflow .ActivitiesWrapper .Activities .ProgressIndicator {
  padding-right: var(--spacing-m);
  min-width: 100%;
}

.WorkflowWrapper .ArrowheadLeftLightIcon {
  position: absolute;
  z-index: 1;
  cursor: pointer;
  padding: 0 var(--spacing-xs);
}
.WorkflowWrapper.Default .ArrowheadLeftLightIcon {
  height: 212px;
}
.WorkflowWrapper.AssetManager .ArrowheadLeftLightIcon {
  height: 94%;
}

.WorkflowWrapper .CircleArrowheadRightDarkIcon,
.WorkflowWrapper .CircleArrowheadLeftDarkIcon {
  position: absolute;
  z-index: 1;
  cursor: pointer;
}
.WorkflowWrapper.Default .CircleArrowheadRightDarkIcon,
.WorkflowWrapper.Default .CircleArrowheadLeftDarkIcon {
  top: var(--spacing-5xl);
}
.WorkflowWrapper.AssetManager .CircleArrowheadRightDarkIcon,
.WorkflowWrapper.AssetManager .CircleArrowheadLeftDarkIcon {
  top: 44%;
}

.WorkflowWrapper .CircleArrowheadRightDarkIcon {
  right: var(--spacing-m);
}

.WorkflowWrapper .CircleArrowheadLeftDarkIcon {
  left: var(--spacing-m);
}

.WorkflowWrapper .CircleArrowheadRightDarkIcon.Enabled {
  opacity: 1;
  transition: all 0.2s;
}

.WorkflowWrapper .CircleArrowheadRightDarkIcon.Disabled {
  opacity: 0;
  transition: all 0.2s;
  pointer-events: none;
}

.WorkflowWrapper .Workflow .WorkflowChildren {
  position: absolute;
  right: var(--spacing-ml);
  top: var(--spacing-m);
  display: flex;
  column-gap: var(--spacing-ml);
}

.Pagination_withCursor .PaginationItem {
  background-color: var(--color-blue-20);
  height: var(--spacing-l);
  width: 120px;
}

.Pagination_withCursor .PaginationItemText {
  font-weight: bold;
}

.PaginationItem_next .PaginationItemText {
  padding-right: var(--spacing-s);
}

.PaginationItem_previous .PaginationItemText {
  padding-left: var(--spacing-s);
}

.PaginationItem_previous + .PaginationItem_next {
  margin-left: var(--spacing-m);
}

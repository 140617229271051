.CodeSnippetTextArea .CodeSnippetTextAreaHeader {
  display: flex;
  justify-content: space-between;
}

.CodeSnippetTextArea .CodeSnippetTextAreaHeader > button {
  font-weight: 700;
  font-family: var(--font-family-default);
  color: var(--color-coral);
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.CodeSnippetTextArea > textarea {
  border: 1px solid var(--color-grey-20);
  padding: var(--spacing-m);
  resize: none;
  width: 100%;
  min-height: 120px;
  color: var(--color-grey-40);
}

.CodeSnippetTextArea > textarea:disabled {
  background-color: var(--color-white);
}

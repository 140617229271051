.StatusCard {
  height: 100%;
  padding: var(--spacing-ml);
}
.StatusCard.AssetManager {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.FixedStatusCard {
  min-width: 100%;
  height: 100%;
  padding: var(--spacing-m);
}
.FixedStatusCard.AssetManager {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: var(--spacing-l);
}

.StatusCard_color_green {
  background-color: var(--color-green);
}

.StatusCard_color_coral {
  background-color: var(--color-coral);
}

.StatusCard_color_grey {
  background-color: var(--color-grey-40);
}

.StatusCard_color_pink {
  background-color: var(--color-pink);
}

.StatusCard_color_cyan {
  background-color: var(--color-cyan);
}

.StatusCard_color_red {
  background-color: var(--color-red);
}

.StatusCard > h3,
.FixedStatusCard > h3 {
  margin-bottom: var(--spacing-ml);
  color: var(--color-white);
}
.StatusCard.AssetManager > p.body1,
.FixedStatusCard.AssetManager > p.body1 {
  margin-bottom: var(--spacing-m);
  color: var(--color-white);
}

.StatusCard > h2,
.FixedStatusCard > h2 {
  color: var(--color-white);
}
.StatusCard.AssetManager > p.body01,
.FixedStatusCard.AssetManager > p.body01 {
  color: var(--color-white);
  margin-top: 0;
}

.ActivityWizardContainer_View {
  flex-direction: column;
  min-height: calc(100vh - 100px);
  height: 100%;
}

.ActivityWizardContainerMain {
  display: flex;
  flex-direction: row;
  min-height: calc(100vh - 100px);
  height: 100%;
}

.ActivityWizardContainer_View .ActivityWizardContainerContent {
  padding-top: var(--spacing-2xl);
  background-color: var(--color-light-grey);
}

.ActivityWizardContainerContent .ActivityWizardContainerContentStepName {
  padding-left: var(--spacing-4xl);
  padding-right: var(--spacing-4xl);
}

.ActivityWizardContainerContent .ActivityWizardContainerStepper {
  padding-left: var(--spacing-4xl);
  padding-right: var(--spacing-4xl);
}

.ActivityWizardReviewStepContainer,
.ActivityWizardContainerContent .ActivityWizardContainerContentStepName,
.ActivityWizardContainerContent .ActivityWizardContainerStepper {
  padding-left: var(--spacing-4xl);
  padding-right: var(--spacing-4xl);
}

.ActivityWizardReviewStepContainer .Review .IsReviewValidSection {
  padding-top: var(--spacing-ll);
}

.ActivityWizardReviewStepContainer .IsReviewValidSection p {
  padding-bottom: var(--spacing-m);
}

.LifecycleStep {
  display: flex;
  --step-size: 24px;
}

.LifecycleStep .LifecycleStepColumn {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.LifecycleStep > svg {
  width: var(--step-size);
  height: var(--step-size);
}

.LifecycleStep .LifecycleStepDivider {
  height: var(--step-size);
  width: 0;
  border: 1px solid;
  border-radius: 2px;
  margin: var(--spacing-s) 0;
}

.LifecycleStep .LifecycleStepDivider.LifecycleStepDivider_completed {
  background-color: var(--color-green);
  border-color: var(--color-green);
}

.LifecycleStep .LifecycleStepDivider.LifecycleStepDivider_not-started {
  background-color: var(--color-green-20);
  border-color: var(--color-green-20);
}

.LifecycleStep .LifecycleStepLabel {
  margin-left: var(--spacing-m);
}

.MergeProjectsModalFormContent {
  margin-top: var(--spacing-ll);
}

.MergeProjectsModalFormContent .MergeProjectsModalFormRegistry {
  margin-top: var(--spacing-m);
}

.MergeProjectsModalFormContent .MergeProjectsModalFormNames {
  display: flex;
  justify-content: center;
  gap: var(--spacing-ml);
  align-items: center;
}

.MergeProjectsModalFormContent .MergeProjectsModalFormNames p {
  font-weight: 700;
}

.CheckboxGroup {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-m);
}

.CheckboxGroup .CheckboxGroupItems {
  display: flex;
  flex-direction: column;
}

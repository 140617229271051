.AboutDeveloperProject {
  border: none;
  background: none;
  padding: 0;
  text-align: left;

  display: flex;
  cursor: pointer;
}

.AboutDeveloperProject > img {
  max-width: 80px;
  max-height: 80px;
}

.AboutDeveloperProject .AboutDeveloperProjectDetails {
  display: flex;
  flex-direction: column;
  margin-left: var(--spacing-m);
  justify-content: center;
  gap: var(--spacing-s);
}

.ProjectTypes {
  padding: var(--spacing-2xl) var(--spacing-4xl) var(--spacing-4xl) var(--spacing-4xl);
  background-color: var(--color-light-grey);
}

.ProjectTypes .CodesTableWrapper {
  display: block;
  overflow-x: auto;
  white-space: nowrap;
  border: 1px solid var(--color-grey-20);
  border-radius: 8px;
}

.ProjectTypes .CodesTableWrapper table {
  width: 100%;
}

.ProjectTypes .CodesTableWrapper td {
  border-left: 1px solid var(--color-grey-20);
  border-top: 1px solid var(--color-grey-20);
  padding: var(--spacing-sl);
  min-width: 400px;
  height: var(--spacing-2xl);
  word-wrap: break-word;
  white-space: pre-wrap;
}

.ProjectTypes .CodesTableWrapper th {
  border-top: 1px solid var(--color-grey-20);
  padding: var(--spacing-sl);
  min-width: 150px;
  width: 300px;
}

.ProjectTypes .CodesTableWrapper tr:first-of-type th {
  border-top: none;
}

.ProjectTypes .CodesTableWrapper tr:first-of-type td {
  border-top: none;
}

.ProjectTypes .CodesTableWrapper td button {
  margin: 0;
}

.ProjectTypes .ProjectTypesPill {
  display: flex;
  gap: var(--spacing-m);
  align-items: center;
}

.ProjectTypes .Pill {
  height: var(--spacing-l);
}

.ProjectTypes .Pill .body2 {
  font-size: var(--font-size-body1);
}

.ProjectTypes .BtnLink {
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
  display: flex;
  font-weight: 700;
  color: var(--color-blue);
  text-decoration: underline;
  margin-left: var(--spacing-sl);
}

.ActivityReviewWorkflowButtonsWrapper .ButtonContainer {
  margin-top: var(--spacing-2xl);
  display: flex;
  column-gap: var(--spacing-m);
  align-items: center;
  width: 100%;
}

.ActivityReviewWorkflowButtonsWrapper .ButtonContainer .Button {
  padding: var(--spacing-sl) var(--spacing-sl) var(--spacing-sl) var(--spacing-sl);
  min-width: 80px;
}

.ActivityReviewWorkflowButtonsWrapper .ButtonContainer svg.DownloadButton {
  width: 20px;
  height: 20px;
}

@media only screen and (min-width: 1864px) {
  .ActivityReviewWorkflowButtonsWrapper .ButtonContainer {
    column-gap: var(--spacing-ml);
  }
}

.ActivityReviewWorkflowButtonsWrapper .BtnLink {
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
  display: flex;
  font-weight: 700;
  color: var(--color-blue);
  text-decoration: underline;
  margin-left: var(--spacing-xs);
}

.Empty.ActivityReviewWorkflowButtonsWrapper {
  color: var(--color-grey-40);
}

.AssignActivityModal .AssigneeDropdownWrapper .Select-dropdown {
  box-shadow: 0 0 var(--box-shadow-spread) var(--color-box-shadow);
}

.AssignActivityModal .AssignActivityModalDatePickerWrapper,
.AssignActivityModal .ReadonlyText {
  text-align: left;
}

.AssignActivityModal .AssigneeDropdownWrapper {
  position: relative;
}
.AssignActivityModal .AssigneeDropdownWrapper .AddAssigneeButton,
.AssignActivityModal .AssigneeDropdownWrapper .RemoveAssigneeButton {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 50px;
  height: 50px;
  right: -52px;
  bottom: 8px;
  cursor: pointer;
  z-index: 1;
}
.AssignActivityModal .AssigneeDropdownWrapper .AddAssigneeButton:hover svg > path,
.AssignActivityModal .AssigneeDropdownWrapper .RemoveAssigneeButton:hover svg > path {
  stroke: var(--color-grey-60);
}

.DropdownButtonWrapper {
  cursor: pointer;
}

.DropdownButtonWrapper .DropdownButton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-s);

  transition-duration: 50ms;
}


.DropdownButtonWrapper:hover .DropdownButton {
  background-color: rgb(230, 230, 230)!important;
}

.DropdownButtonWrapper .DropdownWrapper {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  background-color: var(--color-white);
  box-shadow: 0 0 var(--box-shadow-spread) var(--color-box-shadow);
  z-index: 2;
  width: fit-content;
  height: fit-content;
  white-space: nowrap;
}

.DropdownButtonWrapper .DropdownWrapper .DropdownItem {
  padding: var(--spacing-s);
  display: flex;
  cursor: pointer;
  width: fit-content;

  align-items: center;

  color: var(--color-grey);
  font-family: Space Grotesk;
  font-size: 12px;
  font-weight: 400;
  line-height: 12px; /* 100% */
}

.DropdownButtonWrapper .DropdownWrapper .DropdownItem > svg {
  margin-right: var(--spacing-xs);
  color: var(--color-grey-100);
  width: 16px;
  height: 16px;
}

.DropdownButtonWrapper .DropdownWrapper .DropdownItem:hover > svg > path,
.DropdownButtonWrapper .DropdownWrapper .DropdownItem:hover {
  color: var(--color-grey-60);
  fill: var(--color-blue-60);
}

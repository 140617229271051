.ActivityReviewHeader {
  padding-left: var(--spacing-4xl);
  padding-right: var(--spacing-4xl);
}

.ActivityReviewContent .ActivityReviewContentHeader h3 {
  margin-bottom: var(--spacing-s);
}

.ActivityReviewContent .ActivityReviewContentHeader .ActivityReviewContentTitle {
  display: flex;
}

.ActivityReviewContent {
  margin-bottom: var(--spacing-l);
  padding-left: var(--spacing-4xl);
  padding-right: var(--spacing-4xl);
}


.DocumentDetailsButtonWrapper {
  width: 100%;
  height: 100%;
  
}

.DocumentDetailsButton {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.DocumentDetailsButton > svg
{
  width: 20px;
  height: 20px;
  stroke-width: 1.5px;
}
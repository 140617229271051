.Avatar {
  display: flex;
  align-items: center;
  gap: var(--spacing-s);
}

.Avatar.ImageFirst {
  flex-direction: row-reverse;
  justify-content: flex-end;
}

.Avatar > span {
  font-weight: bold;
}

.AvatarImage {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 1px solid var(--color-grey-20);
}

.AvatarInitial {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: var(--font-size-body1);
  line-height: var(--line-height-body1);
  font-weight: bold;
}

.Avatar.AvatarWithoutName .AvatarImage,
.Avatar.AvatarWithoutName .AvatarInitial {
  width: 30px;
  height: 30px;
}

.AvatarAnchor:hover {
  cursor: pointer;
}

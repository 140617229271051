.DataGrid .DataGridStringWithEllipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.AvatarCell {
  height: 100%;
  display: flex;
  justify-content: center;
}

.TextWithIconCell {
  display: flex;
  gap: var(--spacing-s);
}

.TextWithIconCell svg {
  margin-bottom: calc(-1 * var(--spacing-xxs));
}

.TextWithoutIconCell p,
.TextWithIconCell p {
  font-weight: 700;
}

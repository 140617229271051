.Navigation {
  width: 104px;
  display: flex;
  flex: 0 0 104px;
  flex-direction: column;
  justify-content: flex-start;
  min-height: 100vh;
}

.Navigation .NavigationContainer {
  height: 84vh;
  overflow-y: scroll;
  overflow-x: none;
  scrollbar-width: none;
}

.Navigation .NavigationLogo {
  width: 60px;
  margin: var(--spacing-ll) var(--spacing-ml);
}

.Navigation .SeedLogo {
  width: 40px;
  margin: var(--spacing-l);
}

.Navigation .NavigationElement {
  height: 104px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.Navigation .NavigationElement svg {
  width: 24px;
  height: 24px;
}

.AssetManagerNavigation {
  background-color: var(--color-white);
  box-shadow: 0 0 40px rgba(128, 131, 137, 20%);
}

.AssetManagerNavigation .NavigationElement {
  clip-path: inset(16px round 16px);
}

.AssetManagerNavigation .NavigationElement svg.DashboardLightIcon path,
.AssetManagerNavigation .NavigationElement svg.WatchlistGreyIcon path,
.AssetManagerNavigation .NavigationElement svg.WorkflowGreyIcon path {
  stroke: var(--color-grey-60);
}

.AssetManagerNavigation .NavigationElement svg:not(.DashboardLightIcon, .WatchlistGreyIcon, .WorkflowGreyIcon) path {
  fill: var(--color-grey-60);
}

.AssetManagerNavigation .NavigationElement:hover {
  background-color: rgba(var(--color-grey-rgb), 4%);
}

.AssetManagerNavigation .NavigationElement_active {
  background-color: var(--color-box-shadow-blue);
}

.AssetManagerNavigation .NavigationElement:hover svg.DashboardLightIcon path,
.AssetManagerNavigation .NavigationElement:hover svg.WatchlistGreyIcon path,
.AssetManagerNavigation .NavigationElement:hover svg.WorkflowGreyIcon path {
  stroke: var(--color-blue-80);
}

.AssetManagerNavigation .NavigationElement_active svg.DashboardLightIcon path,
.AssetManagerNavigation .NavigationElement_active svg.WatchlistGreyIcon path,
.AssetManagerNavigation .NavigationElement_active svg.WorkflowGreyIcon path {
  stroke: var(--color-blue);
}

.AssetManagerNavigation
  .NavigationElement:hover
  svg:not(.DashboardLightIcon, .WatchlistGreyIcon, .WorkflowGreyIcon)
  path {
  fill: var(--color-blue-80);
}
.AssetManagerNavigation
  .NavigationElement_active
  svg:not(.DashboardLightIcon, .WatchlistGreyIcon, .WorkflowGreyIcon)
  path {
  fill: var(--color-blue);
}

.AssetManagerNavigation .NavigationFooter {
  margin: auto 13px var(--spacing-m);
}

.DeveloperNavigation {
  background-color: var(--color-grey);
}

.DeveloperNavigation .NavigationElement:hover {
  background-color: var(--color-grey-80);
}

.DeveloperNavigation .NavigationElement_active {
  background-color: var(--color-grey-80);
}

.DevelopmentManagerNavigation {
  background-color: var(--color-iblue);
}

.DevelopmentManagerNavigation .NavigationElement:hover {
  background-color: var(--color-iblue-80);
}

.DevelopmentManagerNavigation .NavigationElement_active {
  background-color: var(--color-iblue-80);
}

.VerifierNavigation {
  background-color: var(--color-forest-green);
}

.VerifierNavigation .NavigationElement:hover {
  background-color: var(--color-forest-green-90);
}

.VerifierNavigation .NavigationElement_active {
  background-color: var(--color-forest-green-90);
}

.PublicTemplate {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-height: calc(100vh - 80px); /* 80px from HeaderNavigation*/
  background-color: var(--color-light-grey);
}

.PublicTemplate .PublicTemplateContent {
  width: 100%;
  max-width: 1760px;
  padding: var(--spacing-ll) var(--spacing-4xl) var(--spacing-4xl) var(--spacing-4xl);
}

.PublicTemplate .PublicTemplateUnwrappedContent {
  width: 100%;
  max-width: 1760px;
  padding: 0;
}

.HideBackToProjectButton {
  visibility: hidden;
}

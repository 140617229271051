.RichContentSection {
  padding: var(--spacing-2xl);
  background-color: var(--color-white);
}

.RichContentSection > h2 {
  margin-bottom: var(--spacing-ll);
}

.RichContentSection h3 {
  margin-bottom: var(--spacing-ml);
}

.RichContentSection p {
  white-space: pre-line;
}

.RichContentSection a {
  display: inline-block;
  color: var(--color-blue);
  text-decoration: underline;
  margin-top: var(--spacing-m);
}

.RichContentSection > div:not(:last-child) {
  margin-bottom: var(--spacing-2xl);
}

.RichContentSection .RichContentSectionPart,
.RichContentSection .RichContentSectionPartReversed {
  display: flex;
  align-items: center;
  gap: calc(var(--spacing-4xl) + var(--spacing-4xl) + var(--spacing-4xl));
}

.RichContentSection .RichContentSectionPart {
  justify-content: space-between;
}

.RichContentSection .RichContentSectionPart .Row {
  width: 100%;
}

.RichContentSection .RichContentSectionPartReversed {
  flex-direction: row-reverse;
  justify-content: start;
  gap: var(--spacing-4xl);
  margin-right: calc(var(--spacing-4xl) + var(--spacing-4xl));
}

.RichContentSection .Column {
  display: flex;
  text-align: left;
}

.RichContentSection .Row .Column.FullSpanText > * {
  padding-right: 0;
}

.RichContentSection .Row .Column > * {
  padding-left: 0;
  padding-right: var(--spacing-m);
}

.RichContentSection .Row .Column > img {
  padding-left: var(--spacing-m);
  padding-right: 0;
}

.RichContentSection .Row .RichContentSectionPartImage {
  height: 264px;
  width: 100%;
  object-fit: cover;
}

.RichContentSection .Row.Reversed .Column > * {
  padding-left: var(--spacing-m);
  padding-right: 0;
}

.RichContentSection .Row.Reversed .Column > img {
  padding-left: 0;
  padding-right: var(--spacing-m);
}

@media only screen and (max-width: 1440px) {
  .RichContentSection .RichContentSectionPart,
  .RichContentSection .RichContentSectionPartReversed {
    gap: var(--spacing-4xl);
  }
  .RichContentSection .RichContentSectionPartReversed {
    margin-right: 0;
  }
}

@media only screen and (max-width: 1024px) {
  .RichContentSection .RichContentSectionPartImage {
    height: 198px;
    width: 342px;
  }
}

@media only screen and (max-width: 768px) {
  .RichContentSection .RichContentSectionPart,
  .RichContentSection .RichContentSectionPartReversed {
    flex-direction: column;
    gap: var(--spacing-ll);
  }

  .RichContentSection .RichContentSectionPartImage {
    height: 198px;
    width: 100%;
  }
}

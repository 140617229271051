.Bar {
  height: 100%;
  width: 100%;
  position: relative;
}

.Bar.Bar_empty {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Header */
.NewDiscussionHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.NewDiscussionHeader p {
  margin-bottom: var(--spacing-ml);
}

.NewDiscussionHeader .DiscussionTitle {
  color: var(--color-blue);
  font-weight: bold;
}
.NewDiscussionHeader .CloseDiscussionIcon {
  cursor: pointer;
}

/* Discussion Card Body */
.DiscussionCardBody {
  display: flex;
  flex-direction: column;
}

.DiscussionCardBody .NewDiscussionMessage {
  margin-bottom: var(--spacing-s);
}

.DiscussionCardBody .VisibilityInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--spacing-s);
  margin-bottom: var(--spacing-s);
}

.DiscussionCardBody .NewDiscussionInfo {
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--spacing-s);
  padding: var(--spacing-s);
  margin-bottom: var(--spacing-l);
  background-color: var(--color-light-grey);
}

.DiscussionCardBody .NewDiscussionInfo > div {
  margin-top: calc(-1 * var(--spacing-sl));
}

.DiscussionCardBody .NewDiscussionMessageActions {
  display: flex;
  flex-direction: row;
  gap: var(--spacing-m);
}

/* New Discussion Button */
.NewDiscussionButton {
  width: fit-content;
}
.NewDiscussionButton .ProfileMenuContainer {
  width: 100%;
}
.NewDiscussionButton .ProfileMenuItem {
  justify-content: center;
}

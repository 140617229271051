.ProjectCardKeyData {
  padding-left: var(--spacing-m);
  padding-right: var(--spacing-m);
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--spacing-m);
  height: 40px;
  border-radius: var(--spacing-xs);
}

.ProjectCardKeyData.ProjectCardKeyDataPrice {
  background-color: var(--color-coral-20);
}

.ProjectCardKeyData.ProjectCardKeyDataNumber {
  background-color: var(--color-green-20);
}

.ProjectCardKeyData.ProjectCardKeyDataType {
  background-color: var(--color-blue-20);
}

.ProjectCardKeyData > span {
  color: var(--color-grey);
}

.ProjectCardKeyData .ProjectCardKeyDataLabel {
  width: 30%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--spacing-m);
}

.ProjectCardKeyData .ProjectCardKeyDataLabel > svg {
  min-width: 16px;
  min-height: 16px;
}

@media only screen and (max-width: 768px) {
  .ProjectCardKeyData {
    padding-left: var(--spacing-s);
    padding-right: var(--spacing-s);
    gap: var(--spacing-sl);
    white-space: nowrap;
  }

  .ProjectCardKeyData .ProjectCardKeyDataLabel {
    width: 50%;
  }
}

.HorizontalProjectCard {
  height: 387px;
  width: 302px;
  background-color: var(--color-white);
  cursor: pointer;
}

.HorizontalProjectCard .HorizontalProjectCardImage {
  height: 302px;
  position: relative;
  background-position: center;
  background-size: cover;
  margin-bottom: var(--spacing-ml);
}

.HorizontalProjectCard .HorizontalProjectCardHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: var(--spacing-s);
}
.HorizontalProjectCard .HorizontalProjectCardHeader > h2 {
  margin-right: var(--spacing-s);
}

.HorizontalProjectCard .HorizontalProjectCardDeveloper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.HorizontalProjectCard .HorizontalProjectCardDeveloper > img {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 1px solid var(--color-grey-20);
  margin-right: var(--spacing-s);
}

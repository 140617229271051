/* Discussion Message Container */
.DiscussionMessageContainer {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-s);
}

/* Discussion Message Header */
.DiscussionMessageHeader {
  display: grid;
  grid-template-columns: 0px 1fr auto auto;
  align-items: center;
  overflow: visible;
}

/* Avatar / Date */
.DiscussionMessageHeader .AvatarAndDate {
  display: flex;
  align-items: center;
  gap: var(--spacing-s);
}

.DiscussionMessageHeader .AvatarAndDate .DateCreated {
  color: var(--color-grey-40);
}

.DiscussionMessageHeader .DiscussionSelectActions .DiscussionUnpublishedMessage {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--spacing-xs);
  margin-right: var(--spacing-s);
}

.DiscussionMessageHeader .DiscussionSelectActions .DiscussionUnpublishedMessage > p {
  color: var(--color-grey-40);
}

.DiscussionMessageHeader .DiscussionSelectActions {
  display: flex;
  align-items: center;
}

.DiscussionMessageHeader .DiscussionSelectActions .ResolveButton {
  cursor: pointer;
}

.DiscussionMessageHeader .DiscussionSelectActions .ResolveButton:hover path {
  fill: var(--color-grey-20);
}

.DiscussionMessageHeader .DiscussionSelectActions .MiscButton {
  cursor: pointer;
  padding-left: var(--spacing-m);
}

.DiscussionMessageHeader .DiscussionSelectActions .MiscButton:hover circle {
  fill: var(--color-grey-40);
}

/* Resolve & Misc - ProfileMenu */

.DiscussionMessageHeader .DiscussionSelectActions .ResolveButton .ProfileMenuContainer {
  height: var(--spacing-l);
}

.DiscussionMessageHeader .DiscussionSelectActions .MiscButton .ProfileMenuContainer {
  height: calc(var(--spacing-xl) + var(--spacing-m));
}
.DiscussionMessageHeader .DiscussionSelectActions .ProfileMenuContainer {
  position: fixed;
  width: max-content;
  padding: 0;
}

.DiscussionMessageHeader .DiscussionSelectActions .ProfileMenuItem {
  justify-content: center;
}

.DiscussionMessageHeader .DiscussionSelectActions .ProfileMenuItem p {
  font-size: var(--font-size-body2);
  line-height: var(--line-height-body2);
  font-family: var(--font-family-body2);
}

.DiscussionMessageContainer .EditMessageContainer {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-m);
}

.DiscussionMessageContainer .EditMessageContainer .EditMessageActions {
  padding: var(--spacing-s) 0 var(--spacing-xs) 0;
}

.EditDocumentModal .ModalRadioButtons .Radio-group {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.EditDocumentModal .ModalButtons {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.EditDocumentModal .ModalDropdown .MultiSelect-dropdown {
  position: relative;
  box-shadow: 0 0 var(--box-shadow-spread) var(--color-box-shadow);
}

.AssignReviewCellFormatter {
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  column-gap: var(--spacing-xs);
}

.AssignReviewCellFormatter > svg {
  width: 20px;
  height: 20px;
}

.AssignReviewCellFormatter > p {
  text-decoration: underline;
  color: var(--color-blue);
  font-weight: 700;
}

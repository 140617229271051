.VersionHistoryDetailsButtonWrapper {
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.VersionHistoryDetailsButton {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.VersionHistoryDetailsButton > svg {
  width: 20px;
  height: 20px;
  stroke-width: 1.5px;
}

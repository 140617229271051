.VisualMediaModal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: calc(100vh - var(--spacing-4xl) * 2);
  width: calc(100% - calc(var(--spacing-4xl) * 4 + var(--spacing-ml) * 2));
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 200;
  object-fit: contain;
}

.VisualMediaModal > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.VisualMediaModal > iframe {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.VersionHistoryEditButtonWrapper,
.VersionHistoryViewButtonWrapper {
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.VersionHistoryEditButton,
.VersionHistoryViewButton {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.VersionHistoryEditButton > svg {
  width: 20px;
  height: 20px;
}

.VersionHistoryViewButton > svg {
  width: 20px;
  height: 20px;
  width: 12px;
  height: 12px;
}

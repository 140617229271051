.KanaTooltip .rc-tooltip.rc-tooltip-zoom-enter,
.KanaTooltip .rc-tooltip.rc-tooltip-zoom-leave {
  display: block;
}
.KanaTooltip .rc-tooltip-zoom-enter,
.KanaTooltip .rc-tooltip-zoom-appear {
  opacity: 0;
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.18, 0.89, 0.32, 1.28);
  animation-play-state: paused;
}
.KanaTooltip .rc-tooltip-zoom-leave {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.6, -0.3, 0.74, 0.05);
  animation-play-state: paused;
}
.KanaTooltip .rc-tooltip-zoom-enter.rc-tooltip-zoom-enter-active,
.KanaTooltip .rc-tooltip-zoom-appear.rc-tooltip-zoom-appear-active {
  animation-name: rcToolTipZoomIn;
  animation-play-state: running;
}
.KanaTooltip .rc-tooltip-zoom-leave.rc-tooltip-zoom-leave-active {
  animation-name: rcToolTipZoomOut;
  animation-play-state: running;
}
@keyframes rcToolTipZoomIn {
  0% {
    opacity: 0;
    transform-origin: 50% 50%;
    transform: scale(0, 0);
  }
  100% {
    opacity: 1;
    transform-origin: 50% 50%;
    transform: scale(1, 1);
  }
}
@keyframes rcToolTipZoomOut {
  0% {
    opacity: 1;
    transform-origin: 50% 50%;
    transform: scale(1, 1);
  }
  100% {
    opacity: 0;
    transform-origin: 50% 50%;
    transform: scale(0, 0);
  }
}
.KanaTooltip.rc-tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  visibility: visible;

  /*Kana styles*/
  padding-top: var(--spacing-s);
  width: 300px;
  opacity: 1;
}
.KanaTooltip.rc-tooltip-hidden {
  display: none;
}

.KanaTooltip .rc-tooltip-content {
  /*Kana styles*/
  background-color: var(--color-white);
  padding-left: var(--spacing-m);
  padding-right: var(--spacing-m);
  box-shadow: 0 0 var(--box-shadow-spread) var(--color-box-shadow);
}

.KanaTooltip .rc-tooltip-placement-top,
.KanaTooltip .rc-tooltip-placement-topLeft,
.KanaTooltip .rc-tooltip-placement-topRight {
  padding: var(--spacing-xs) 0 var(--spacing-s) 0;
}
.KanaTooltip .rc-tooltip-placement-right,
.KanaTooltip .rc-tooltip-placement-rightTop,
.KanaTooltip .rc-tooltip-placement-rightBottom {
  padding: 0 var(--spacing-xs) 0 var(--spacing-s);
}
.KanaTooltip .rc-tooltip-placement-bottom,
.KanaTooltip .rc-tooltip-placement-bottomLeft,
.KanaTooltip .rc-tooltip-placement-bottomRight {
  padding: var(--spacing-s) 0 var(--spacing-xs) 0;
}
.KanaTooltip .rc-tooltip-placement-left,
.KanaTooltip .rc-tooltip-placement-leftTop,
.KanaTooltip .rc-tooltip-placement-leftBottom {
  padding: 0 var(--spacing-s) 0 var(--spacing-xs);
}
.KanaTooltip .rc-tooltip-inner {
  text-align: left;
  text-decoration: none;
  border-radius: 6px;
  min-height: 34px;

  /*Kana styles*/
  background-color: var(--color-white);
  color: var(--color-grey);
  box-shadow: none;
  padding: 0;
}
.KanaTooltip .rc-tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;

  /*Kana styles*/
  display: none;
}

/*Kana styles*/
.TooltipContainer > h3 {
  padding-top: var(--spacing-xs);
  padding-bottom: var(--spacing-s);
}

.TooltipContainer .body1 {
  padding-bottom: var(--spacing-ml);
  color: var(--color-grey);
}

.TooltipContainerIcon {
  display: flex;
  justify-content: flex-end;
  padding-top: var(--spacing-m);
}

.TooltipContainerIcon > svg {
  width: 16px;
  height: 16px;
  padding: var(--spacing-xs) var(--spacing-xs);
  cursor: pointer;
}

.TooltipChild {
  display: flex;
  cursor: pointer;
  user-select: none;
}

.TooltipChild.TooltipFill:not(.TooltipChildLink) path {
  fill: var(--color-grey-60);
}

.TooltipChild.TooltipFill:not(.TooltipChildLink) svg:hover path {
  fill: var(--color-grey-40);
}

.TooltipChild.TooltipChildLink p {
  padding-right: var(--spacing-s);
}

.TooltipChild.TooltipFill.TooltipChildLink h3 {
  padding-right: var(--spacing-s);
  color: var(--color-coral);
}

.TooltipChild.TooltipFill.TooltipChildLink:hover h3 {
  color: var(--color-coral-60);
}

.TooltipChild.TooltipFill.TooltipChildLink path {
  fill: var(--color-coral);
}

.TooltipChild.TooltipFill.TooltipChildLink:hover path {
  fill: var(--color-coral-60);
}

.AddGroupProjectAutocomplete {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.AddGroupProjectAutocomplete h3 {
  padding-right: var(--spacing-m);
}

.AddGroupProjectAutocomplete div {
  flex-grow: 1;
}

.AddGroupProjectAutocomplete div .rc-select {
  margin: 0;
}

.AddGroupProjectModalButtons {
  display: flex;
  flex-direction: row;
  gap: var(--spacing-ml);
}

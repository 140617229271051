.HorizontalLifecycle {
  display: flex;
  flex-direction: row;
  --step-size: 24px;
}

.HorizontalLifecycle .LifecycleStep {
  display: flex;
  align-items: center;
}

.HorizontalLifecycle .LifecycleStep > svg {
  width: var(--step-size);
  height: var(--step-size);
  margin-right: var(--spacing-m);
}

.HorizontalLifecycle .LifecycleStep .Divider {
  width: var(--step-size);
  height: 0;
  border: 1px solid;
  border-radius: 2px;
  margin: 0 var(--spacing-s);
}

.HorizontalLifecycle .LifecycleStep .Divider.Divider_completed {
  background-color: var(--color-green);
  border-color: var(--color-green);
}

.HorizontalLifecycle .LifecycleStep .Divider.Divider_not-started {
  background-color: var(--color-green-20);
  border-color: var(--color-green-20);
}

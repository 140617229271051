.GroupProject {
  border: none;
  background: none;
  padding: 0;
  text-align: left;
  display: flex;
  cursor: pointer;
}

.GroupProject > img {
  max-width: 80px;
  max-height: 80px;
}

.GroupProject .GroupProjectDetails {
  display: flex;
  flex-direction: column;
  margin-left: var(--spacing-m);
  justify-content: center;
  gap: var(--spacing-s);
}

.GroupProject .GroupProjectDetails .GroupProjectDescription {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  line-clamp: 3;
}

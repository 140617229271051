.DiscussionMessageHeader .TabNotificationContainer {
  z-index: 2;
  position: relative;
  margin-left: calc(-1 * var(--spacing-m));
}

.DiscussionMessageHeader .DiscussionUnpublishedMessage {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--spacing-xs);
  margin-right: var(--spacing-s);
}

.DiscussionMessageHeader .DiscussionUnpublishedMessage > p {
  color: var(--color-grey-40);
}


.DiscussionMessageContainer .DiscussionMessageActions {
  opacity: 0;
  position: relative;
}

.DiscussionMessageContainer:hover .DiscussionMessageActions {
  opacity: 100;
  transition-duration: 100ms;
}

.NavigationTooltip.rc-tooltip {
  width: 80px;
  padding: 0;
  background-color: var(--color-white);
  opacity: 0.9;
  cursor: pointer;
  position: fixed;
}

.NavigationTooltip.rc-tooltip-hidden {
  display: none;
}

.NavigationTooltip .rc-tooltip-content {
  width: 100%;
  height: 100%;
}

.NavigationTooltip .rc-tooltip-inner {
  min-height: 30px;
}

.NavigationTooltipContainer {
  display: flex;
  justify-content: center;
  line-height: var(--line-height-h2);
  color: var(--color-grey);
}

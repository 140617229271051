.ReportingStandardsSection {
  height: 100%;
  padding: var(--spacing-xl) var(--spacing-xl);
}

.ReportingStandardsSection .Approved,
.ReportingStandardsSection .NotApproved {
  height: inherit;
  display: flex;
  flex-direction: column;
  row-gap: var(--spacing-ml);
}

.ReportingStandardsSectionApproved {
  background-color: var(--color-green);
}

.ReportingStandardsSectionNotApproved {
  background-color: var(--color-grey-60);
}

.ReportingStandardsSection .Approved > h3,
.ReportingStandardsSection .NotApproved > h3 {
  color: var(--color-white);
}

.ReportingStandardsSection .Approved .WhatIsThisWrapper,
.ReportingStandardsSection .NotApproved .WhatIsThisWrapper {
  display: flex;
  flex-direction: row;
  column-gap: var(--spacing-s);
}

.ReportingStandardsSection .Approved .WhatIsThisWrapper .TooltipChild.TooltipChildLink p {
  color: var(--color-green-20);
  text-decoration: underline;
}

.ReportingStandardsSection .NotApproved .WhatIsThisWrapper .TooltipChild.TooltipChildLink p {
  color: var(--color-grey-20);
  text-decoration: underline;
}

.ReportingStandardsSection .Approved .WhatIsThisWrapper .TooltipChild.TooltipChildLink:hover p,
.ReportingStandardsSection .Approved .WhatIsThisWrapper .TooltipChild.TooltipChildLink:hover path {
  color: var(--color-grey-20);
  fill: var(--color-grey-20);
}

.ReportingStandardsSection .NotApproved .WhatIsThisWrapper .TooltipChild.TooltipChildLink:hover p,
.ReportingStandardsSection .NotApproved .WhatIsThisWrapper .TooltipChild.TooltipChildLink:hover path {
  color: var(--color-light-grey);
  fill: var(--color-light-grey);
}

.MultipleFileUploadWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.MultipleFileUploadWrapper > label {
  margin-bottom: var(--spacing-s);
}

.MultipleFileUploadWrapper > label + p {
  margin-bottom: var(--spacing-s);
}

.MultipleFileUpload {
  display: inline-block;
  border: 1px dashed var(--color-grey-40);
  background-color: var(--color-light-grey);
}

.MultipleFileUploadWrapper.MultipleFileUploadWrapperDisabled .MultipleFileUpload:hover {
  background-color: var(--color-light-grey);
}

.MultipleFileUpload:hover {
  background-color: var(--color-blue-20);
}

.MultipleFileUpload:focus-visible {
  border: 1px solid var(--color-grey-40);
  outline: none;
}

.MultipleFileUploadContent {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: var(--spacing-l);
  gap: var(--spacing-ml);
}

.MultipleFileUploadContent.TextWithLoadingSpinner {
  gap: var(--spacing-m);
}

.MultipleFileUploadContent.TextWithLoadingSpinner > p {
  user-select: none;
}

.MultipleFileUploadContentDragOver {
  background-color: var(--color-blue-20);
}

.MultipleFileUploadInformation {
  margin-top: var(--spacing-s);
  color: var(--color-grey-40);
}

.MultipleFileUploadItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: var(--spacing-s);
  padding: var(--spacing-s);
  border: 1px solid var(--color-grey-40);
  border-radius: 2px;
}

.MultipleFileUploadItemDefault {
  background-color: var(--color-grey-20);
}

.MultipleFileUploadItem .MultipleFileUploadItemFilename {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.MultipleFileUploadItem > .MultipleFileUploadItemFilename > p {
  margin-left: var(--spacing-s);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.MultipleFileUploadItem .MultipleFileUploadItemActions > button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.MultipleFileUploadItemDragOver {
  background-color: var(--color-blue-20);
}

.MultipleFileUploadItem .MultipleFileUploadItemActions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--spacing-s);
}

.MultipleFileUploadItem .MultipleFileUploadItemActions .MultipleFileUploadItemPreview {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--spacing-s);
}

.MultipleFileUploadItem .MultipleFileUploadItemActions .MultipleFileUploadItemPreviewBtn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.MultipleFileUploadItem .MultipleFileUploadItemActions .MultipleFileUploadItemPreview .Divider_vertical {
  background-color: var(--color-grey-20);
  width: 16px;
  height: 16px;
}

.MultipleFileUploadItem.MultipleFileUploadItemDefault
  .MultipleFileUploadItemActions
  .MultipleFileUploadItemPreview
  .Divider_vertical {
  background-color: var(--color-grey-60);
}

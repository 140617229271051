.GroupProjectsContainer h3 {
  margin: 0;
}

.GroupProjectsContainer .GroupProjectsDescription {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 6;
  line-clamp: 6;
}

.GroupProjectsContainer .GroupProjects {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-m);
}

.GroupProjectsContainer a {
  font-size: var(--font-size-body2);
}

.UnitsAveragePriceContainer {
  display: flex;
  flex-direction: column;
  row-gap: var(--spacing-ml);
  min-height: 278.8px;
  height: 100%;
  width: 100%;
  padding: var(--spacing-l);
  background-color: var(--color-white);
}

.UnitsAveragePriceContainer .TitleSection {
  display: flex;
  flex-direction: row;
  column-gap: var(--spacing-s);
  align-items: center;
}

.UnitsAveragePriceContainer .InfoSection {
  display: flex;
  flex-direction: column;
  row-gap: var(--spacing-m);
}

.UnitsAveragePriceContainer .InfoSection .Value {
  color: var(--color-blue);
  display: flex;
  flex-direction: row;
  align-items: baseline;
}
.UnitsAveragePriceContainer .InfoSection .Value h1.Number {
  font-size: 56px;
}
.UnitsAveragePriceContainer .InfoSection .Value h1.Magnitude {
  font-size: 24px;
  line-height: normal;
  text-transform: lowercase;
}

.UnitsAveragePriceContainer .ToggleSection {
  display: flex;
  flex-direction: row;
  column-gap: var(--spacing-s);
  align-items: center;
  margin-top: auto;
}
.UnitsAveragePriceContainer .ToggleSection p.Inactive {
  color: var(--color-grey-60);
}
.UnitsAveragePriceContainer .ToggleSection p.ActiveAvgPrice {
  color: var(--color-blue-60);
}
.UnitsAveragePriceContainer .ToggleSection p.ActiveUnits {
  color: var(--color-blue);
}

@media only screen and (max-width: 1320px) {
  .UnitsAveragePriceContainer .InfoSection .Value h1.Number {
    font-size: 50px;
  }
  .UnitsAveragePriceContainer .InfoSection .Value h1.Magnitude {
    font-size: 20px;
  }
}

@media only screen and (max-width: 1024px) {
  .UnitsAveragePriceContainer .InfoSection .Value h1.Number {
    font-size: 44px;
  }
  .UnitsAveragePriceContainer .InfoSection .Value h1.Magnitude {
    font-size: 18px;
  }
}

.DatePicker {
  font-family: var(--font-family-a);
  width: 100%;
  display: flex;
  flex-direction: column;
}

.DatePicker label {
  margin-bottom: -20px;
  z-index: 1;
}

.DatePicker .CalendarIcon {
  position: relative;
  top: 36px;
  left: 18px;
  z-index: 1;
}

.DatePicker .rc-calendar {
  width: 100%;
  box-sizing: unset;
  height: min-content;
  border: none;
  border-radius: 0;
  box-shadow: none;
  background-color: transparent;
  margin-top: 0;
}

/* --- Input-Box --- */

.DatePicker .rc-calendar-input-wrap {
  border-bottom: none;
  border: 1px solid var(--color-grey-20);
  padding: var(--spacing-sl) 0 var(--spacing-sl) var(--spacing-xl);
  background-color: var(--color-white);
}

.DatePicker .rc-calendar-input {
  font-family: var(--font-family-body1);
  font-size: var(--font-size-body1);
  font-weight: 400;
  color: var(--color-grey);
}

.DatePicker .rc-calendar-input::placeholder {
  color: var(--color-grey-40);
  opacity: 1;
}

.DatePicker .rc-calendar-input.rc-calendar-input-invalid {
  border: none;
}

.DatePicker .rc-calendar-clear-btn {
  display: none;
}

.DatePicker .hidden .rc-calendar-header,
.DatePicker .hidden .rc-calendar-body,
.DatePicker .hidden .rc-calendar-date-panel {
  visibility: collapse;
}

/* --- Headers --- */

.DatePicker .rc-calendar-header {
  height: min-content;
  padding: var(--spacing-ml) calc(var(--spacing-ll) + var(--spacing-sl)) var(--spacing-m);
  border-bottom: none;
  background-color: var(--color-white);
}

.DatePicker .rc-calendar-month-panel-header,
.DatePicker .rc-calendar-year-panel-header {
  border-bottom: none;
  padding: calc(var(--spacing-ml) - 2px) calc(var(--spacing-ll) + var(--spacing-sl));
}

/* --- Calender Type Buttons --- */

.DatePicker .rc-calendar-year-select,
.DatePicker .rc-calendar-month-select,
.DatePicker .rc-calendar-month-panel-year-select,
.DatePicker .rc-calendar-year-panel-decade-select {
  font-size: var(--font-size-body1);
  font-weight: 400;
  color: var(--color-blue);
}

.DatePicker .rc-calendar-month-panel-year-select,
.DatePicker .rc-calendar-year-panel-decade-select {
  font-weight: 700;
}

.DatePicker .rc-calendar-year-select:hover,
.DatePicker .rc-calendar-month-select:hover,
.DatePicker .rc-calendar-month-panel-year-select:hover,
.DatePicker .rc-calendar-year-panel-decade-select:hover {
  color: var(--color-blue-60);
}

/* --- Pagination Buttons --- */

.DatePicker .rc-calendar-header .rc-calendar-next-month-btn,
.DatePicker .rc-calendar-header .rc-calendar-next-year-btn,
.DatePicker .rc-calendar-header .rc-calendar-prev-month-btn,
.DatePicker .rc-calendar-header .rc-calendar-prev-year-btn {
  color: var(--color-blue);
  top: 2px;
  width: 20px;
  height: 34px;
}

.DatePicker .rc-calendar-header .rc-calendar-next-month-btn {
  left: 78%;
}

.DatePicker .rc-calendar-header .rc-calendar-prev-month-btn {
  left: 16%;
}

.DatePicker a.rc-calendar-next-month-btn:after {
  background-color: currentColor;
  width: 8px;
  height: 16px;
  display: inline-block;
  content: "";
  mask: url(../../../../assets/icons/calendar-single-arrow-right.svg);
  mask-size: cover;
}

.DatePicker a.rc-calendar-prev-month-btn:after {
  background-color: currentColor;
  width: 8px;
  height: 16px;
  display: inline-block;
  content: "";
  mask: url(../../../../assets/icons/calendar-single-arrow-left.svg);
  mask-size: cover;
}

.DatePicker .rc-calendar-header .rc-calendar-month-panel-next-year-btn,
.DatePicker .rc-calendar-header .rc-calendar-year-panel-next-decade-btn,
.DatePicker .rc-calendar-header .rc-calendar-month-panel-prev-year-btn,
.DatePicker .rc-calendar-header .rc-calendar-year-panel-prev-decade-btn {
  color: var(--color-blue);
  top: 25px;
  width: 20px;
  height: 34px;
}

.DatePicker .rc-calendar-header .rc-calendar-month-panel-next-year-btn,
.DatePicker .rc-calendar-header .rc-calendar-year-panel-next-decade-btn {
  left: 82.5%;
}

.DatePicker .rc-calendar-header .rc-calendar-month-panel-prev-year-btn,
.DatePicker .rc-calendar-header .rc-calendar-year-panel-prev-decade-btn {
  left: 12.6%;
}

.DatePicker a.rc-calendar-next-year-btn:after,
.DatePicker a.rc-calendar-month-panel-next-year-btn:after,
.DatePicker a.rc-calendar-year-panel-next-decade-btn:after {
  background-color: currentColor;
  width: 14px;
  height: 16px;
  display: inline-block;
  content: "";
  mask: url(../../../../assets/icons/calendar-double-arrow-right.svg);
  mask-size: cover;
}

.DatePicker a.rc-calendar-prev-year-btn:after,
.DatePicker a.rc-calendar-month-panel-prev-year-btn:after,
.DatePicker a.rc-calendar-year-panel-prev-decade-btn:after {
  background-color: currentColor;
  width: 14px;
  height: 16px;
  display: inline-block;
  content: "";
  mask: url(../../../../assets/icons/calendar-double-arrow-left.svg);
  mask-size: cover;
}

.DatePicker a.rc-calendar-next-month-btn:hover:after,
.DatePicker a.rc-calendar-next-year-btn:hover:after,
.DatePicker a.rc-calendar-month-panel-next-year-btn:hover:after,
.DatePicker a.rc-calendar-year-panel-next-decade-btn:hover:after,
.DatePicker a.rc-calendar-prev-month-btn:hover:after,
.DatePicker a.rc-calendar-prev-year-btn:hover:after,
.DatePicker a.rc-calendar-month-panel-prev-year-btn:hover:after,
.DatePicker a.rc-calendar-year-panel-prev-decade-btn:hover:after {
  background-color: var(--color-blue-60);
}

/* --- Disable Decade Calendar --- */

.DatePicker .rc-calendar-year-panel-decade-select {
  pointer-events: none;
}

/* --- Bodies --- */

.DatePicker .rc-calendar-body {
  height: min-content;
  padding: 0px var(--spacing-ll) var(--spacing-l);
  background-color: var(--color-white);
}

.DatePicker .rc-calendar-date {
  font-family: var(--font-family-body2);
  font-size: var(--font-size-body2);
  font-weight: 400;
  border-radius: 13px 13px;
  color: var(--color-grey);
}

.DatePicker .rc-calendar-date-panel,
.DatePicker .rc-calendar-month-panel,
.DatePicker .rc-calendar-year-panel {
  box-shadow: 0 0 var(--box-shadow-spread) var(--color-box-shadow);
  position: absolute;
  width: 411px;
  z-index: 3;
  margin-top: var(--spacing-s);
}

.DatePicker .rc-calendar-month-panel-table,
.DatePicker .rc-calendar-year-panel-table {
  width: 306px;
  margin: 0px 42px;
}

.DatePicker .rc-calendar-month-panel-cell .rc-calendar-month-panel-month,
.DatePicker .rc-calendar-year-panel-cell .rc-calendar-year-panel-year {
  font-size: var(--font-size-body1);
  font-weight: 400;
  border-radius: unset;
  width: 90px;
}

.DatePicker .rc-calendar-date:hover,
.DatePicker .rc-calendar-month-panel-cell .rc-calendar-month-panel-month:hover,
.DatePicker .rc-calendar-year-panel-cell .rc-calendar-year-panel-year:hover {
  background: var(--color-light-grey);
}

.DatePicker .rc-calendar-selected-day .rc-calendar-date {
  background: var(--color-light-grey);
}

.DatePicker .rc-calendar-selected-date .rc-calendar-date,
.DatePicker .rc-calendar-month-panel-selected-cell .rc-calendar-month-panel-month,
.DatePicker .rc-calendar-year-panel-selected-cell .rc-calendar-year-panel-year,
.DatePicker .rc-calendar-selected-date .rc-calendar-date:hover,
.DatePicker .rc-calendar-month-panel-selected-cell .rc-calendar-month-panel-month:hover,
.DatePicker .rc-calendar-year-panel-selected-cell .rc-calendar-year-panel-year:hover {
  background: var(--color-blue);
  color: var(--color-white);
}

.DatePicker .rc-calendar-today .rc-calendar-date {
  border: none;
}

.DatePicker .rc-calendar-column-header-inner {
  font-family: var(--font-family-body1);
  font-size: var(--font-size-body1);
  color: var(--color-grey-60);
}

.DatePicker .rc-calendar-last-month-cell .rc-calendar-date,
.DatePicker .rc-calendar-next-month-btn-day .rc-calendar-date,
.DatePicker .rc-calendar-year-panel-next-decade-cell .rc-calendar-year-panel-year {
  color: var(--color-grey-20);
}

.DatePicker p.DateFormatText {
  margin-top: var(--spacing-s);
  color: var(--color-grey-40);
}

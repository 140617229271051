.PublicProjectTypeTemplate > .HeroSectionImage {
  width: 100%;
  height: 360px;
  background-position: center;
  background-size: cover;
}

.PublicProjectTypeTemplate > button {
  margin-left: calc(var(--spacing-4xl) + calc(-1 * var(--spacing-l)));
}

.PublicProjectTypeTemplate .PublicProjectTypeTemplateContents {
  padding: 0 var(--spacing-4xl) var(--spacing-4xl) var(--spacing-4xl);
  background-color: var(--color-light-grey);
}

.PublicProjectTypeTemplate .HideBackToProjectButton {
  visibility: hidden;
}

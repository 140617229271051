.DownloadDocumentCellFormatter {
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.DownloadDocumentCellFormatter > svg {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

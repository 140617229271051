.CodeStatusContainer {
  color: var(--color-grey);

  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  row-gap: var(--spacing-ml);
}

.CodeStatusContainer.ProjectPage {
  height: 160px;
}

.CodeStatusContainer .CodeStatusTextArea {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: var(--spacing-s);
}

.CodeStatusContainer .CodeStatusTextArea p.body3 {
  color: var(--color-grey-40);
}

.CodeStatusContainer .CodeStatusGraphArea {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: var(--spacing-s);
}
.CodeStatusContainer .CodeStatusGraphArea .CodeStatusGraph {
  width: 100%;
  height: 24px;
  display: flex;
  flex-direction: row;
  column-gap: var(--spacing-s);
}

.CodeStatusContainer .CodeStatusGraphArea .CodeStatusGraph .GraphBar {
  width: 100%;
}

.CodeStatusContainer .CodeStatusGraphArea .CodeStatusGraph.Established .GraphBar {
  background-color: var(--color-green);
}

.CodeStatusContainer .CodeStatusGraphArea .CodeStatusGraph.Emerging .GraphBar {
  background-color: var(--color-grey-20);
}

.CodeStatusContainer .CodeStatusGraphArea .CodeStatusGraphLabelArea {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.CodeStatusContainer .CodeStatusGraphArea .CodeStatusGraphLabelArea p.body3 {
  margin: 0;
}

@media only screen and (max-width: 1320px) {
  .CodeStatusContainer .CodeStatusTextArea {
    flex-direction: column;
  }
}

.Step {
  color: var(--color-white);
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: var(--spacing-m);
}

.Step .Header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.Step .Header:hover {
  cursor: pointer;
}

.Step .Header .HeaderLeftSection {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: var(--spacing-m);
}

.Step .Header .HeaderLeftSection p {
  font-weight: 700;
}

.Step .Header .HeaderLeftSection.Review p,
.Step .Header .HeaderLeftSection.Introduction p {
  color: var(--color-green);
}
.AssetManager.Step .Header .HeaderLeftSection.Review p,
.AssetManager.Step .Header .HeaderLeftSection.Introduction p {
  color: var(--color-blue);
  font-weight: 700;
}

.AssetManager.Step .Header .HeaderLeftSection p {
  font-weight: 500;
}
.AssetManager.Step .Header .HeaderLeftSection p.Current.body2 {
  color: var(--color-blue);
  font-weight: 700;
}

.Step .Header .HeaderLeftSection > div {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: var(--spacing-s);
}

.Step .Header .ExpandOrContractButton {
  display: flex;
  margin-left: var(--spacing-sl);
}

.AssetManager.Step .Header .ExpandOrContractButton path {
  stroke: var(--color-grey-80);
}
.Verifier.Step .Header .ErrorIcon path {
  fill: var(--color-red-60);
}

.Step .SubSteps {
  margin-top: var(--spacing-ml);
  margin-bottom: var(--spacing-s);
}

.Step .SubSteps.LastStep {
  margin-bottom: 0;
}

.Step .SubSteps .SubStep {
  position: relative;
  display: flex;
  margin-left: var(--spacing-s);
  align-items: center;
}

.Step .SubSteps .SubStep:not(:last-child) {
  margin-bottom: var(--spacing-ml);
}

.Step .SubSteps .SubStep .SubStepSelectable {
  cursor: pointer;
  display: flex;
  column-gap: var(--spacing-l);
  align-items: center;
}

.Step .SubSteps .SubStep .SubStepSelectable > div {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: var(--spacing-s);
  font-weight: 700;
}
.AssetManager.Step .SubSteps .SubStep .SubStepSelectable > div {
  font-weight: 500;
}
.AssetManager.Step .SubSteps .SubStep.Current .SubStepSelectable > div {
  font-weight: 700;
}

.Step .SubSteps .SubStep .VerticalLineLightIcon {
  position: absolute;
  margin-bottom: calc(var(--spacing-ll) - var(--spacing-xs));
  margin-left: var(--spacing-xs);
}

@media only screen and (min-width: 1024px) {
  .Step .SubSteps .SubStep .VerticalLineLightIcon {
    margin-bottom: var(--spacing-ll);
  }
}

.ProjectStatusContainer {
  padding: var(--spacing-m) var(--spacing-m);
  background-color: var(--color-white);
  color: var(--color-grey);

  height: 136px;
  width: 100%;
  display: flex;
  flex: 1;
  align-items: flex-start;
}

.ProjectStatusContainer .ProjectStatusGraph {
  display: flex;
  flex: 3;
  height: 104px;
  column-gap: var(--spacing-xxs);
  margin-right: var(--spacing-m);
}

.ProjectStatusContainer .ProjectStatusGraph.GraphBar1Filled div:nth-child(-n + 1),
.ProjectStatusContainer .ProjectStatusGraph.GraphBar2Filled div:nth-child(-n + 2),
.ProjectStatusContainer .ProjectStatusGraph.GraphBar3Filled div:nth-child(-n + 3),
.ProjectStatusContainer .ProjectStatusGraph.GraphBar4Filled > div {
  background-color: var(--color-green);
}

.ProjectStatusContainer .ProjectStatusGraph .GraphBarEmpty {
  background-color: var(--color-green-20);
}

.ProjectStatusContainer .ProjectStatusHeaderArea {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 7;
}

.ProjectStatusContainer .ProjectStatusHeaderArea > h2 {
  margin-bottom: var(--spacing-s);
}

.ProjectStatusContainer .ProjectStatusHeaderArea p.body3 {
  color: var(--color-grey-40);
  margin-top: auto;
}

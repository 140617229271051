.UserHeader {
  display: flex;
  flex-direction: row;
  margin-top: var(--spacing-ll);
  align-items: center;
  justify-content: space-between;
}

.UserHeader .NotificationIcon {
  margin: 0 var(--spacing-ll) 0 auto;
  cursor: pointer;
}

.UserHeader .ProfileMenuItemWrapper {
  width: 100%;
}

.UserHeader .ProfileMenuItemDivider {
  margin: var(--spacing-s) 0;
}

.UserHeader > button {
  line-height: var(--line-height-body1);
  padding-left: 0;
}

.UserHeader * svg {
  width: 16px;
  height: 16px;
  padding-bottom: var(--spacing-xs);
}

.RowPadding {
  padding: var(--spacing-xs) 0 var(--spacing-xs) var(--spacing-s);
}

.ChartLegend {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.ChartLegendBox {
  width: 12px;
  height: 12px;
  border-radius: 2px;
}

.ChartLegendNoValue {
  padding: var(--spacing-s) var(--spacing-s);
}

.ChartLegendValue {
  padding: var(--spacing-s) var(--spacing-m);
}

.ChartLegendValue p.body2,
.ChartLegendNoValue p.body2 {
  font-weight: bold;
}

.CreatedComponentRow {
  display: flex;
  justify-content: space-between;
  padding: var(--spacing-s) var(--spacing-4xl);
}

.CreatedComponentRow:not(.IsForViewMode):hover,
.CreatedComponentRow.IsDiscussionOpen,
.CreatedComponentRow:focus-within {
  background-color: var(--color-wizard-light-grey-row-hover);
}

.CreatedComponentRow.IsForTableRepeater {
  flex-wrap: nowrap;
}

.CreatedComponentRow .IconsContainer {
  display: flex;
  column-gap: var(--spacing-ml);
}

.CreatedComponentRow .CreateDiscussionIconContainer {
  visibility: hidden;
  margin-right: var(--spacing-4xl);
  cursor: pointer;
}

.CreatedComponentRow:hover .CreateDiscussionIconContainer,
.CreatedComponentRow:focus-within .CreateDiscussionIconContainer {
  visibility: visible;
}

.CreatedComponentRow .ViewDiscussionIconContainer {
  margin-right: var(--spacing-4xl);
  cursor: pointer;
  display: flex;
  align-items: center;
}

.CreatedComponentRow .TabNotificationContainer {
  margin-left: var(--spacing-xs);
}

.UneditableComponentRow {
  padding-left: var(--spacing-4xl);
  padding-right: var(--spacing-4xl);
}

.TabNotificationIndicator {
  background-color: var(--color-coral);
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.Tabs .TabNotificationContainer {
  width: 0px;
  height: 8px;
  position: relative;
  display: inline-block;
  justify-items: center;
  align-items: center;
}

.Tabs .TabNotificationIndicator {
  position: absolute;
  margin-left: calc(-1 * var(--spacing-m));
}

.VersionBannerWrapper {
  width: 100%;
  height: 100px;
  z-index: 10;
}

.VersionBannerWrapper .VersionBanner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--spacing-ml) var(--spacing-ll) var(--spacing-ml) var(--spacing-l);
  filter: opacity(98%);

  width: inherit;
}

.VersionBannerWrapper .AssetManager.VersionBanner {
  background-color: var(--color-light-grey);
  border-bottom: 1px solid var(--color-box-shadow-blue);
}

.VersionBannerWrapper .Developer.VersionBanner {
  background-color: var(--color-grey);
}

.VersionBannerWrapper .DevelopmentManager.VersionBanner {
  background-color: var(--color-iblue);
}

.VersionBannerWrapper .Verifier.VersionBanner {
  background-color: var(--color-forest-green);
}

.VersionBannerWrapper .VersionBanner .TextArea {
  height: 48px;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  justify-content: center;
  row-gap: var(--spacing-s);
}

.VersionBannerWrapper .VersionBanner .FormArea {
  display: flex;
  align-items: center;
}

.VersionBannerWrapper .VersionBanner .TextArea > h3,
.VersionBannerWrapper .VersionBanner .FormArea > h3 {
  font-size: var(--font-size-body1);
}

.VersionBannerWrapper .AssetManager.VersionBanner .TextArea,
.VersionBannerWrapper .AssetManager.VersionBanner .FormArea {
  color: var(--color-grey);
}

.VersionBannerWrapper .Developer.VersionBanner .TextArea,
.VersionBannerWrapper .DevelopmentManager.VersionBanner .TextArea,
.VersionBannerWrapper .Developer.VersionBanner .FormArea,
.VersionBannerWrapper .DevelopmentManager.VersionBanner .FormArea,
.VersionBannerWrapper .Verifier.VersionBanner .TextArea,
.VersionBannerWrapper .Verifier.VersionBanner .FormArea {
  color: var(--color-white);
}

.VersionBannerWrapper .VersionBanner .FormArea .Select {
  position: relative;
  top: var(--spacing-xs);
  width: 210px;
  margin-left: var(--spacing-m);
}

.VersionBannerWrapper .DevelopmentManager.VersionBanner .FormArea .Select,
.VersionBannerWrapper .DevelopmentManager.VersionBanner .FormArea .Select-item,
.VersionBannerWrapper .DevelopmentManager.VersionBanner .FormArea .Select-open.Select .Select-selection-item {
  color: var(--color-iblue);
}

.VersionBannerWrapper .DevelopmentManager.VersionBanner .FormArea .Select-arrow svg > path {
  stroke: var(--color-iblue);
}

.VersionBannerWrapper .VersionBanner .FormArea .Button {
  height: 48px;
  font-size: var(--font-size-body1);
  margin-left: var(--spacing-l);
}

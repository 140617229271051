.ManageProjectGroup {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  background-color: var(--color-light-grey);
}

.ManageProjectGroup .HeaderContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: var(--spacing-m);
}

.ManageProjectGroup .HeaderContainer > button:first-of-type {
  margin-left: calc(-1 * var(--spacing-l));
  margin-top: var(--spacing-l);
}

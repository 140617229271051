.Repeater {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.Repeater .RepeaterRow {
  display: flex;
  flex-direction: row;
}

.Repeater .RepeaterRowHr {
  margin: 0 0 var(--spacing-ll) 0;
  border: none;
  background-color: var(--color-grey-20);
  height: 1px;
}

.RepeaterRow {
  margin-bottom: var(--spacing-2xl);
}

.RepeaterRowRemoveButton {
  display: flex;
  align-items: center;
}

.RepeaterFooter {
  padding-left: var(--spacing-4xl);
  padding-right: var(--spacing-4xl);
}

.RepeaterRowRemoveButton .Button_secondary.Button_small,
.RepeaterFooter .Button_secondary {
  min-width: 0;
  min-height: var(--font-size-body2); /* same as Button_small */
  padding: var(--spacing-sl);
}
.RepeaterRowRemoveButton .Button_secondary.Button_small {
  margin: 0 0 0 var(--spacing-4xl);
}

.RepeaterRow .Button_secondary svg > path,
.RepeaterFooter .Button_secondary svg > path {
  stroke: var(--color-blue);
}

.RepeaterRowContent {
  flex-direction: column;
  display: flex;
  flex-grow: 1;
  min-width: 0;
  gap: var(--spacing-ml);
}

.RepeaterRowContent > *:last-child {
  margin-bottom: 0;
}

.Repeater .CopyPasteActions {
  display: flex;
  gap: var(--spacing-ml);
  padding-left: calc(var(--spacing-4xl) + var(--spacing-sl));
  padding-right: calc(var(--spacing-4xl) + var(--spacing-sl));
}

.Repeater .RepeaterRowDividerContainer {
  padding-left: var(--spacing-4xl);
  padding-right: var(--spacing-4xl);
}

.Repeater .RepeaterRowDivider {
  margin: var(--spacing-ml) var(--spacing-sl) var(--spacing-s);
}

.AssetManagerDashboardPieCardContainer {
  height: 100%;
  padding: var(--spacing-s);
}

.AssetManagerDashboardPieCardHeader {
  display: flex;
  gap: var(--spacing-xs);
  align-items: center;
}

.AssetManagerDashboardPieCardContent {
  height: calc(100% - var(--spacing-ml) - var(--line-height-h3));
}

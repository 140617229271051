/* Tab Container */
.ToggleTabs {
  position: relative;
  display: flex;
  flex-direction: row;
  padding: var(--spacing-xs);
  gap: var(--spacing-s);
  background: var(--color-blue-20);
  border-radius: 100px;
  overflow: hidden;
}

/* Sliding Tab */
.ToggleTabs_indicator {
  position: absolute;
  margin: var(--spacing-xs) 0;
  top: 0;
  bottom: 0;
  left: 0;
  width: 0;
  background: var(--color-blue);
  border-radius: 100px;
  transition:
    transform 0.3s ease,
    width 0.3s ease;
}

/* Default Tab */
.ToggleTabsTab {
  position: relative;
  padding: var(--spacing-s) var(--spacing-m);
  color: var(--color-blue);
  cursor: pointer;
  border-radius: 100px;
}

.ToggleTabsTab:not(.ToggleTabsTab_active):hover {
  background-color: var(--color-blue-40);
}

.ToggleTabsTab .CountSpan {
  color: var(--color-grey-60);
}

.ToggleTabsTab .body2 {
  font-weight: 400;
}

/* Active Tab */
.ToggleTabsTab.ToggleTabsTab_active {
  color: var(--color-white);
}

.ToggleTabsTab.ToggleTabsTab_active .CountSpan {
  color: var(--color-white);
  font-weight: 400;
  transition: color 0.3s ease;
}

.ToggleTabsTab.ToggleTabsTab_active .body2 {
  font-weight: 700;
  transition: color 0.2s ease;
}

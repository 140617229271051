.KPITooltip.rc-tooltip {
  width: max-content;
  padding-left: var(--spacing-s);
  padding-right: var(--spacing-s);
  background-color: var(--color-white);
  opacity: 0.9;
  cursor: pointer;
}

.KPITooltip.rc-tooltip-hidden {
  display: none;
}

.KPITooltip .rc-tooltip-content {
  width: 100%;
  height: 100%;
}

.KPITooltip .rc-tooltip-inner {
  min-height: 30px;
}

.KPITooltipContainer {
  display: flex;
  justify-content: center;
  line-height: var(--line-height-h2);
  color: var(--color-grey);
}

.SubmitActivityModalButtons {
  display: flex;
  justify-content: center;
  gap: var(--spacing-ml);
}

.SubmitActivityModalRadioButtons .Radio-group {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.SubmitActivityModalInfoBox,
.SubmitActivityModalSelect {
  text-align: left;
}

.SubmitActivityModalDownloadDocumentsButton {
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: var(--spacing-l) var(--spacing-ll) var(--spacing-l) var(--spacing-l);
  background-color: var(--color-light-grey);
  color: var(--color-blue);
  border-radius: 8px;
  cursor: pointer;
}
.SubmitActivityModalDownloadDocumentsButton:hover {
  background-color: var(--color-wizard-light-grey-row-hover);
}

.SubmitActivityModalDownloadDocumentsButton .DownloadDocumentsButtonSection {
  display: flex;
  align-items: center;
  column-gap: var(--spacing-s);
}

.SubmitActivityModalDownloadDocumentsButton .DownloadDocumentsButtonSection > h4 {
  font-size: 14px;
  text-decoration: underline;
}

/* Discussion Thread Body */
.DiscussionThreadBody {
  display: flex;
  flex-direction: column;
  margin-top: var(--spacing-ml);
  gap: var(--spacing-ml);
}

.DiscussionThreadBody .ResolvedContainer .ResolvedHeader :not(.Divider_horizontal),
.DiscussionThreadBody .ResolvedContainer .DiscussionMessageHeader > div:not(.DiscussionSelectActions),
.DiscussionThreadBody .ResolvedContainer .DiscussionMessageBody,
.DiscussionThreadBody .ResolvedContainer .Divider_vertical {
  opacity: 30%;
}

.DiscussionThreadBody .ResolvedContainer .ResolvedHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 8px;
  margin-bottom: var(--spacing-m);
}

.DiscussionThreadBody .ResolvedContainer .ResolvedHeader > p {
  white-space: nowrap;
}

.DiscussionThreadBody .ResolvedContainer .ResolvedHeader .Divider_horizontal {
  width: 100%;
}

.DiscussionThreadBody .DiscussionFirstMessageContainer {
  margin-bottom: var(--spacing-ml);
}
.DiscussionThreadBody .ReplyContainer {
  display: flex;
  flex-direction: row;
  gap: var(--spacing-ml);
  margin-bottom: var(--spacing-ml);
}
.DiscussionThreadBody .ReplyContainer .Divider_vertical {
  height: auto;
  background-color: var(--color-grey-20);
}
.DiscussionThreadBody .DiscussionMessageReplies {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: var(--spacing-ml);
}
.DiscussionThreadBody .DiscussionNoMessages {
  padding-bottom: var(--spacing-2xl);
}

.NewDiscussionContainer,
.NewReplyContainer {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-m);
}

.NewDiscussionContainer .VisibilityInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--spacing-s);
  margin-bottom: calc(-1 * var(--spacing-s));
}

.NewDiscussionContainer .NewDiscussionInfo,
.NewReplyContainer .NewDiscussionInfo {
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--spacing-s);
  padding: var(--spacing-s);
  margin-top: calc(-1 * var(--spacing-s));
  margin-bottom: var(--spacing-m);
  background-color: var(--color-light-grey);
}

.NewReplyContainer .VisibilityInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--spacing-s);
  margin-bottom: calc(-1 * var(--spacing-s));
}

.NewReplyContainer .NewReplyInfo > div,
.NewDiscussionContainer .NewDiscussionInfo > div {
  margin-top: calc(-1 * var(--spacing-sl));
}

.NewDiscussionContainer .NewDiscussionMessageActions,
.NewReplyContainer .NewReplyMessageActions {
  display: flex;
  flex-direction: row;
  gap: var(--spacing-m);
}

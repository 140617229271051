.DeveloperScreen {
  min-height: 100vh;
  display: flex;
  background-color: var(--color-light-grey);
}

/* For the media queries we are counting in the LeftNavigation width 104px  */
@media only screen and (min-width: 768px) {
  .DeveloperContainer {
    padding: 0 var(--spacing-ll) 0 calc(var(--spacing-ll) + 104px);
  }
}

@media only screen and (min-width: 1024px) {
  .DeveloperContainer {
    padding: 0 var(--spacing-2xl) 0 calc(var(--spacing-2xl) + 104px);
  }
}

@media only screen and (min-width: 1440px) {
  .DeveloperContainer {
    padding: 0 var(--spacing-4xl) 0 calc(var(--spacing-4xl) + 104px);
    max-width: 1864px; /* calc(1600px + 104px + (var(--spacing-2xl) * 2)) - this is 1600px + left nav bar 104px + padding on each side */
  }
}

/* Removing margin if screen width is 80px larger on each side than the max-width set of 1600px + the left navigation width 104px */
@media only screen and (min-width: 1864px) {
  .DeveloperContainer {
    /* Centering the content in the middle of the screen counting in LeftNavigation */
    padding: 0 0 0 104px;
    max-width: 1704px; /* 1600px + left nav bar 104px */
  }
}

.Navigation {
  position: fixed;
  left: 0;
}

.DeveloperContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 1024px;
  margin-right: auto;
  margin-left: auto;
}

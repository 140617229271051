.ImageButton {
  cursor: pointer;
}

.ImageButton span {
  font-size: var(--font-size-body3);
  line-height: var(--line-height-body3-tablet);
}

.ImageButton svg {
  padding-left: var(--spacing-xs);
}

.SingleFileUploadInputWrapper {
  width: 100%;
}

.SingleFileUploadInputWrapper > input {
  display: none;
}

.SingleFileUploadInputWrapper_disabled > label {
  color: var(--color-grey-40);
}

.SingleFileUploadInputWrapper_disabled .SingleFileUploadInputFileBox {
  background-color: transparent;
}

.SingleFileUploadInputWrapper_disabled .SingleFileUploadInputBtnV2,
.SingleFileUploadInputWrapper_disabled .SingleFileUploadInputBtnV2:hover {
  cursor: default;
  background-color: var(--color-grey-40);
  color: var(--color-grey-20);
}

.SingleFileUploadInput {
  display: flex;
  margin-top: var(--spacing-s);
}
.SingleFileUploadInputWrapper .rc-upload:focus-visible .SingleFileUploadInput {
  /*Kana styles*/
  outline: 1px dashed var(--color-grey-40);
  outline-offset: var(--spacing-xs);
}
.SingleFileUploadInputWrapper .rc-upload:focus-visible {
  outline: none;
}

.SingleFileUploadInputFileBox {
  display: inline-block;
  background-color: var(--color-white);
  width: 100%;
  min-width: 100px;
  border: 1px solid var(--color-grey-20);
  padding: 0 var(--spacing-m);

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Align inline level elements vertically */
.SingleFileUploadInputFileBox:after {
  content: "";
  display: inline-block;
  vertical-align: middle;
  height: 100%;
}

.SingleFileUploadInputBtnV2 {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: var(--spacing-m);
  cursor: pointer;
  white-space: nowrap;
  color: var(--color-white);
  background: var(--color-blue);
  border: none;
  min-width: 136px;
}

.SingleFileUploadInputBtnV2.TextWithLoadingSpinner {
  user-select: none;
}

.SingleFileUploadInputBtnV2.TextWithLoadingSpinner > p {
  color: var(--color-white);
}

.SingleFileUploadInputBtnV2:hover {
  background: var(--color-blue-60);
}

.SingleFileUploadInputBtnV2:active {
  background: var(--color-blue-80);
}

.SingleFileUpload {
  display: flex;
}

.SingleFileUploadPreview {
  margin-left: var(--spacing-2xl);
}

.SingleFileUploadPreview .LogoContainer {
  margin-top: var(--spacing-m);
}

.SingleFileUpload .SingleFileUploadPreviewBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: var(--spacing-s);
}

.SingleFileUpload .SingleFileUploadPreviewBtn .Button {
  margin-top: 11px;
  padding: 0;
  min-height: 50px;
  height: 50px;
}

.ProjectDocumentHistoryDetails .ProjectDocumentHistoryHeader {
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--spacing-2xl);
}

.ProjectDocumentHistoryDetails .ProjectDocumentHistoryDetails {
  display: flex;
  gap: var(--spacing-xl);
  margin-bottom: var(--spacing-l);
}

.ProjectDocumentHistoryDetails .ProjectDocumentHistoryDetails .ReadonlyText {
  width: auto;
}

.ProjectDocumentHistoryDetails .DataGridContainer {
  margin-top: var(--spacing-ml);
}

.PreviewButton {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-s);
}

.PreviewButton .PreviewButtonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 170px;
  height: 74px;
  border: 1px solid var(--color-grey-20);
}

.PreviewButton .PreviewButtonContainer > div {
  padding: var(--spacing-m);
  background-color: white;
}

.PreviewButton .PreviewButtonContainer > div > span {
  user-select: none;
  font-size: var(--font-size-body1);
}

.Wizard {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  height: 100%;
}

.AssetManager.Wizard {
  background-color: var(--color-light-grey);
  color: var(--color-blue);
  border-right: 1px solid var(--color-box-shadow-blue);
}

.Developer.Wizard {
  background-color: var(--color-grey);
}

.DevelopmentManager.Wizard {
  background-color: var(--color-iblue);
}

.Verifier.Wizard {
  background-color: var(--color-forest-green);
}

.Wizard .WizardLogoContainer {
  width: fit-content;
  margin: calc(var(--spacing-ll) + var(--spacing-xs)) var(--spacing-l);
}

.Wizard .WizardLogo {
  min-width: 60px;
  min-height: 14px;
}

.AssetManager.Wizard .WizardLogo path {
  fill: var(--color-grey);
}

.Wizard .HorizontalStatusContainer {
  padding: var(--spacing-ml) var(--spacing-l);
}

.Developer.Wizard .HorizontalStatusContainer {
  background-color: var(--color-wizard-light-grey);
}

.DevelopmentManager.Wizard .HorizontalStatusContainer {
  background-color: rgba(219, 220, 223, 0.1);
}

.AssetManager.Wizard .HorizontalStatusContainer {
  border-top: 1px solid var(--color-box-shadow-blue);
  border-bottom: 1px solid var(--color-box-shadow-blue);
  background-color: rgba(255, 255, 255, 0.1);
}
.AssetManager.Wizard .HorizontalStatusContainer p {
  color: var(--color-blue);
}

.Verifier.Wizard .HorizontalStatusContainer {
  background-color: var(--color-forest-green-90);
}

.Wizard .Steps {
  margin: var(--spacing-l) var(--spacing-l) calc(2 * var(--spacing-5xl));
}

.Wizard .Steps .Divider_horizontal {
  margin: var(--spacing-l) 0;
}

.AssetManager.Wizard .Steps .Divider_horizontal {
  position: relative;
  background-color: var(--color-box-shadow-blue);
  width: calc(100% + 2 * var(--spacing-l));
  right: var(--spacing-l);
}

.AssetManager.Wizard .Steps .Step {
  color: var(--color-grey-80);
}

.Wizard .Button {
  position: absolute;
  bottom: 0;
  max-width: 160px;
  max-height: 50px;
  margin: var(--spacing-l);
}

.PieChart {
  display: flex;
  align-items: center;
  height: 100%;
}

.PieChart.PieChart.Vertical {
  flex-direction: column;
}

.PieChart.Primary.Horizontal .Chart {
  margin-right: var(--spacing-l);
}

.PieChart.Seconday.Horizontal .Chart {
  margin-right: var(--spacing-m);
}

.PieChart.Vertical .Chart {
  margin-bottom: var(--spacing-m);
}

.Name .NameHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--spacing-ml) var(--spacing-ml) var(--spacing-ml) 0;
  cursor: pointer;
}

.Name .NameContent {
  padding: 0 var(--spacing-ml) var(--spacing-ml) 0;
}

.DeveloperSingleCardTemplate .TemplateTabs {
  background-color: var(--color-white);
  padding-top: var(--spacing-2xl);
  padding-left: var(--spacing-2xl);
  padding-bottom: var(--spacing-4xl);
  margin-bottom: var(--spacing-4xl);
  box-shadow: 0 0 var(--box-shadow-spread) var(--color-box-shadow);
}

.DeveloperSingleCardTemplate .TitleAndPill {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--spacing-m);
}

.DeveloperSingleCardTemplate .DeveloperInfo {
  display: flex;
  align-items: center;
  gap: var(--spacing-s);
}

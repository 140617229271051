.Slider .tooltip {
  position: relative;
  top: var(--spacing-ml);
  min-width: 60px;
  text-align: center;
  width: fit-content;
  margin-left: -30px; /* min-width / 2 */
  user-select: none;
  color: var(--color-grey-40);
  font-size: var(--font-size-body3);
}

.Slider .tooltip-2 {
  top: 5px;
}

.Slider .rc-slider-handle-2 {
  margin-top: -23px;
}

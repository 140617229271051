.AboutTheCodeSection .AboutTheCodeHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.AboutTheCodeSection .ReportingStandards {
  display: flex;
  align-items: center;
  column-gap: var(--spacing-s);
}

.AboutTheCodeSection .ReportingStandards.True {
  color: var(--color-green);
}

.AboutTheCodeSection .ReportingStandards.False {
  color: var(--color-grey-60);
}

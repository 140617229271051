.LoadingSpinner {
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.LoadingSpinner.Light {
  border: 2px solid var(--color-white);
  border-bottom-color: transparent;
}

.LoadingSpinner.Dark {
  border: 2px solid black;
  border-bottom-color: transparent;
}

.LoadingSpinner.Blue {
  border: 2px solid var(--color-blue);
  border-bottom-color: transparent;
}

.LoadingSpinner.Small {
  width: 14px;
  height: 14px;
}

.LoadingSpinner.Medium {
  width: 28px;
  height: 28px;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.TableRepeaterContainer {
  width: 100%;
  background-color: transparent;
}

.TableRepeater {
  width: 100%;
  margin-bottom: var(--spacing-l);
}

.TableRepeater > * > tr {
  border: 0;
  border-bottom: 1px solid var(--color-grey-20);
}

.TableRepeater > * > tr.TableRepeaterRow > td,
.TableRepeater > * > tr.TableRepeaterRow > th {
  padding: var(--spacing-s);
}

.TableRepeater > * > tr.TableRepeaterRow > th {
  text-align: left;
}

.TableRepeaterRowRemoveButton .Button_secondary.Button_small,
.TableRepeaterFooter .Button_secondary {
  min-width: 0;
  min-height: var(--font-size-body2); /* same as Button_small */
  padding: 0;
  margin-left: var(--spacing-sl);
}

.TableRepeaterRowRemoveButton .Button_secondary.Button_small {
  margin: 0 auto;
}

.TableRepeaterRow .Button_secondary svg > path,
.TableRepeaterFooter .Button_secondary svg > path {
  stroke: var(--color-blue);
}

.TableRepeaterFooter {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-l);
}

/* special formatting for input fields when included in a TableRepeater */
.TableRepeaterRow > td > .form_field input {
  height: var(--spacing-xl);
}

.TableRepeaterRow > td .SingleFileUploadInput {
  margin-top: 0;
}

.TableRepeaterRow > td {
  vertical-align: middle;
}

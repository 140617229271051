.PreviewBanner {
  width: 100%;
  height: 136px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--spacing-ml) var(--spacing-4xl);
  background-color: var(--color-grey);
  position: sticky;
  top: 0;
  z-index: 3;
}

.PreviewBanner .PreviewBannerInfo {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-s);
  color: var(--color-white);
}

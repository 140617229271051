.VisualMediaModalBackdrop {
  width: 100%;
  height: 100%;
  padding-left: var(--spacing-4xl);
  padding-right: var(--spacing-4xl);
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  background-color: var(--color-grey);
  opacity: 0.96;
}

.VisualMediaModalBackdropHeader {
  height: var(--spacing-4xl);
  padding-top: var(--spacing-l);
  padding-bottom: var(--spacing-l);
  width: 50%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.VisualMediaModalBackdropHeader > span {
  color: var(--color-white);
}

.VisualMediaModalBackdropCloseButton {
  height: 100%;
  width: 80px;
  display: flex;
  align-items: center;
  gap: var(--spacing-s);
  cursor: pointer;
  outline: none;
  border: none;
  background-color: transparent;
}

.VisualMediaModalBackdropCloseButton:focus-visible {
  outline: 1px solid var(--color-white);
  outline-offset: var(--spacing-xs);
}

.VisualMediaModalBackdropCloseButton > h3 {
  color: var(--color-white);
}

.VisualMediaModalBackdropPaginationLeft {
  width: calc(30% - var(--spacing-4xl) * 2);
  display: flex;
  justify-content: flex-start;
  position: absolute;
  top: 50%;
  left: 4%;
}

.VisualMediaModalBackdropPaginationRight {
  width: calc(30% - var(--spacing-4xl) * 2);
  display: flex;
  justify-content: flex-end;
  position: absolute;
  top: 50%;
  right: 4%;
}

.VisualMediaModalBackdropPaginationLeft > button,
.VisualMediaModalBackdropPaginationRight > button {
  width: var(--spacing-3xl);
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
  background-color: transparent;
}

.DashboardPieCardContainer {
  height: 100%;
}

.DashboardPieCardHeader {
  display: flex;
  gap: var(--spacing-xs);
}

.DashboardPieCardContent {
  height: calc(100% - var(--spacing-ml) - var(--line-height-h3));
}

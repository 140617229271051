.QuickActionsMenu {
  width: 203px;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: var(--spacing-m) var(--spacing-ml) var(--spacing-m) var(--spacing-ml);
  background-color: var(--color-blue-20);
  cursor: pointer;
  user-select: none;
}

.QuickActionsMenu:focus-visible {
  background-image: repeating-linear-gradient(
      0deg,
      var(--color-grey-40),
      var(--color-grey-40) 4px,
      transparent 4px,
      transparent 9px,
      var(--color-grey-40) 9px
    ),
    repeating-linear-gradient(
      90deg,
      var(--color-grey-40),
      var(--color-grey-40) 4px,
      transparent 4px,
      transparent 9px,
      var(--color-grey-40) 9px
    ),
    repeating-linear-gradient(
      180deg,
      var(--color-grey-40),
      var(--color-grey-40) 4px,
      transparent 4px,
      transparent 9px,
      var(--color-grey-40) 9px
    ),
    repeating-linear-gradient(
      270deg,
      var(--color-grey-40),
      var(--color-grey-40) 4px,
      transparent 4px,
      transparent 9px,
      var(--color-grey-40) 9px
    );
  background-size:
    2px 100%,
    100% 2px,
    2px 100%,
    100% 2px;
  background-position:
    0 0,
    0 0,
    100% 0,
    0 100%;
  background-repeat: no-repeat;
  outline: none;
}

.QuickActionsMenu > span {
  color: var(--color-blue);
}

.QuickActionsMenu .Divider {
  height: 40px;
  border: 1px solid var(--color-blue-60);
}

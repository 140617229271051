.Gallery {
  width: 100%;
  height: 544px;
  position: relative;
  background-position: center;
  background-size: cover;
  cursor: pointer;
}

.GalleryYoutubeThumbnailWrapper {
  position: relative;
  width: 100%;
  height: 544px;
}

.GalleryYoutubeThumbnailWrapper .YoutubeThumbnailOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--color-grey);
  filter: opacity(20%);
  z-index: 1;
}

.GalleryYoutubeThumbnailWrapper .YoutubeThumbnail {
  height: 100%;
  background-position: center;
  background-size: cover;
  cursor: pointer;
  filter: blur(20px);
  clip-path: inset(0px 0px 0px 0px);
}

/* calcs to centre: 40px - half icon width, 272px - half img height*/
.GalleryYoutubeThumbnailWrapper .YoutubePlayIcon {
  width: 80px;
  height: 80px;
  position: relative;
  cursor: pointer;
  bottom: calc(272px + 40px);
  left: calc(50% - 40px);
  z-index: 2;
}

.GalleryYoutubeThumbnailWrapper:hover .YoutubePlayIcon {
  filter: opacity(75%);
}

.GalleryMultiple {
  width: 100%;
  position: relative;
}

.Gallery button:nth-child(1),
.GalleryMultiple button:nth-child(2) {
  width: 180px;
  height: 56px;
  position: absolute;
  bottom: var(--spacing-ml);
  right: var(--spacing-ml);
}

.Gallery button:nth-child(2),
.GalleryMultiple button:nth-child(3) {
  width: 140px;
  height: 56px;
  position: absolute;
  bottom: var(--spacing-ml);
  right: 230px;
}

.GalleryMultiple .FirstImage {
  height: 100%;
  background-position: center;
  background-size: cover;
  cursor: pointer;
}

.GalleryMultiple .SecondAndThirdImagesContainer {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-ml);
}

.GalleryMultiple .SecondAndThirdImagesContainer > div {
  height: 260px;
  background-position: center;
  background-size: cover;
  cursor: pointer;
}

.PublicCodeComparisonTemplate {
  padding: var(--spacing-2xl) var(--spacing-4xl) var(--spacing-4xl) var(--spacing-4xl);
  background-color: var(--color-light-grey);
}

.PublicCodeComparisonTemplate .CodesTableWrapper {
  display: block;
  overflow-x: auto;
  white-space: nowrap;
  border: 1px solid var(--color-grey-20);
  border-radius: 8px;
}

.PublicCodeComparisonTemplate .CodesTableWrapper table {
  width: 100%;
}

.PublicCodeComparisonTemplate .CodesTableWrapper th {
  text-align: left;
  border-top: 1px solid var(--color-grey-20);
  padding: var(--spacing-m);
}

.PublicCodeComparisonTemplate .CodesTableWrapper tr:first-of-type th {
  border-top: none;
}

.PublicCodeComparisonTemplate .CodesTableWrapper td {
  border-left: 1px solid var(--color-grey-20);
  border-top: 1px solid var(--color-grey-20);
  padding: var(--spacing-m);
}

.PublicCodeComparisonTemplate .CodesTableWrapper tr:first-of-type td {
  border-top: none;
}

.PublicCodeComparisonTemplate .CodesTableWrapper td button {
  margin: 0;
}

.PublicCodeComparisonTemplate .CodesComparisonTableWrapper {
  display: block;
  overflow-x: auto;
  white-space: nowrap;
}

.PublicCodeComparisonTemplate .CodesComparisonTableWrapper table {
  width: 100%;
}

.PublicCodeComparisonTemplate .CodesComparisonTableWrapper th {
  min-width: 250px;
  width: 412px;
  word-wrap: break-word;
  white-space: pre-wrap;
  background-color: var(--color-blue-20);
  border-top: 1px solid var(--color-white);
  text-align: left;
  padding: var(--spacing-m) var(--spacing-ml);
}

.PublicCodeComparisonTemplate .CodesComparisonTableWrapper th p {
  color: var(--color-grey);
}

.PublicCodeComparisonTemplate .CodesComparisonTableWrapper th .body4 {
  margin-left: var(--spacing-ml);
}

.PublicCodeComparisonTemplate .CodesComparisonTableWrapper.WithoutStrongHeaders th .body4 {
  margin-left: 0;
}

.PublicCodeComparisonTemplate .CodesComparisonTableWrapper td {
  min-width: 250px;
  word-wrap: break-word;
  white-space: pre-wrap;
  background-color: var(--color-white);
  text-align: center;
  border-top: 1px solid var(--color-grey-20);
  padding: var(--spacing-m);
}

.PublicCodeComparisonTemplate .CodesComparisonTableWrapper tr:first-of-type td {
  border-top: none;
}

.PublicCodeComparisonTemplate .BtnLink {
  border: none;
  background: none;
  padding: 0;
  margin: auto;
  cursor: pointer;
  display: flex;
  font-weight: 700;
  color: var(--color-blue);
  text-decoration: underline;
}

.PublicCodeComparisonTemplate .CheckIcon svg path {
  fill: var(--color-green);
}

.PublicCodeComparisonTemplate .DisabledText {
  color: var(--color-grey-40);
}

.PublicCodeComparisonTemplate .Pill {
  min-width: 170px;
}

.PublicCodeComparisonTemplate .InformationBox h3 {
  font-weight: 400;
}

.PublicCodeComparisonTemplate .CodeCategoryContainer {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: var(--spacing-m);
  margin-bottom: var(--spacing-4xl);
}

.PublicCodeComparisonTemplate .CodeCategory div {
  padding: var(--spacing-ml);
  height: 100%;
  border-radius: 6px;
}

.PublicCodeComparisonTemplate .CodeCategory > p {
  font-weight: 700;
  margin-bottom: var(--spacing-m);
}

.PublicCodeComparisonTemplate .CodeCategory div p {
  font-weight: 400;
  color: var(--color-grey);
}

.PublicCodeComparisonTemplate .CodeCategoryBrown {
  color: var(--color-coral);
}

.PublicCodeComparisonTemplate .CodeCategoryBrown div {
  background-color: var(--color-coral-20);
  border: 2px dashed var(--color-coral);
}

.PublicCodeComparisonTemplate .CodeCategoryGreen {
  color: var(--color-green);
}

.PublicCodeComparisonTemplate .CodeCategoryGreen div {
  background-color: var(--color-green-20);
  border: 2px dashed var(--color-green);
}

.PublicCodeComparisonTemplate .CodeCategoryBlue {
  color: var(--color-cyan);
}

.PublicCodeComparisonTemplate .CodeCategoryBlue div {
  background-color: var(--color-cyan-20);
  border: 2px dashed var(--color-cyan);
}

.PublicCodeComparisonTemplate .CodeCategoryGrey {
  color: var(--color-grey-40);
}

.PublicCodeComparisonTemplate .CodeCategoryGrey div {
  background-color: var(--color-grey-20);
  border: 2px dashed var(--color-grey-40);
}

.PublicCodeComparisonTemplate .CodeCategoryYellow {
  color: var(--color-yellow-dark);
}

.PublicCodeComparisonTemplate .CodeCategoryYellow div {
  background-color: var(--color-yellow-20);
  border: 2px dashed var(--color-yellow-dark);
}

.PublicCodeComparisonTemplate .ProjectTypesText {
  display: flex;
}

.PublicCodeComparisonTemplate .ProjectTypesText button {
  margin-left: var(--spacing-xs);
}

.PublicCodeComparisonTemplate .TooltipContainer {
  display: flex;
  margin-top: var(--spacing-s);
  margin-bottom: var(--spacing-m);
}

.PublicCodeComparisonTemplate .TooltipContainer p {
  color: var(--color-grey-40);
  font-weight: 700;
  text-decoration: underline;
}

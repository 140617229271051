.YoutubePlayerContainer {
  position: relative;
  /*This padding keeps a 16*9 aspect ratio*/
  padding-bottom: 56.25%;
}

.YoutubePlayerContainer > iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media only screen and (max-width: 800px) {
  .YoutubePlayerContainer > iframe {
    width: 140%;
    height: 140%;
  }
}

@media only screen and (min-width: 1244px) {
  .YoutubePlayerContainer > iframe {
    width: 80%;
    height: 80%;
  }
}

@media only screen and (min-width: 1800px) {
  .YoutubePlayerContainer > iframe {
    width: 60%;
    height: 60%;
  }
}

@media only screen and (min-width: 2600px) {
  .YoutubePlayerContainer > iframe {
    width: 50%;
    height: 50%;
  }
}

.RichListContentSection {
  padding: var(--spacing-2xl) var(--spacing-3xl) var(--spacing-4xl) var(--spacing-2xl);
  background-color: var(--color-white);
}

.RichListContentSection > h2 {
  margin-bottom: var(--spacing-ll);
}

.RichListContentSection .body1 {
  white-space: pre-line;
}

.RichListContentSection img {
  max-width: 100%;
  max-height: 100px;
}

.RichListContentSection div:last-of-type {
  margin-bottom: 0;
}

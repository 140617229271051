.ErrorBox {
  padding: var(--spacing-ml) var(--spacing-ml);
  background-color: var(--color-red-20);
}

.ErrorBox .ErrorBoxHeaderArea {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: var(--spacing-m);
  margin-bottom: var(--spacing-sl);
}

.ErrorBox .ErrorBoxHeaderArea svg {
  width: 20px;
  height: 20px;
}

.ErrorBox .ErrorBoxHeaderArea > h3 {
  margin-bottom: var(--spacing-xs);
}

.ErrorBox .ErrorBoxMessagesArea > p {
  margin-left: calc(var(--spacing-ml) + 20px + var(--spacing-m) - var(--spacing-ml));
  text-align: left;
}

.ErrorBox .ErrorBoxMessagesArea > ul {
  padding-left: 0;
  margin-top: 0;
  margin-left: var(--spacing-2xl);
  text-align: left;
}

.ProjectDocumentHistoryDetails .ProjectDocumentHistoryDetails {
  display: flex;
  gap: var(--spacing-xl);
  margin-bottom: var(--spacing-l);
}

.ProjectDocumentHistoryDetails .ProjectDocumentHistoryDetails .ReadonlyText {
  width: auto;
}

.ProjectDocumentHistoryDetails .DataGridContainer {
  margin-top: var(--spacing-ml);
}

.ProjectDocumentHistoryDetails .CustomVariant input {
  height: var(--spacing-xl);
}

.ProjectDocumentHistoryDetails .SubmitButton {
  width: 120px;
}

.ProjectDocumentHistoryDetails .SubmitButton .Button {
  min-width: 120px;
  min-height: var(--spacing-xl);
  height: var(--spacing-xl);
  margin-top: 26px;
}

.TypeRatingsSection {
  display: flex;
  flex-direction: row;
  justify-content: center;
  column-gap: var(--spacing-4xl);
}

.TypeRatingsSection > .TypeRating {
  width: 140px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  row-gap: var(--spacing-l);
}

.TypeRatingsSection > .TypeRating > div {
  display: flex;
  flex-direction: column;
  row-gap: var(--spacing-s);
}

.TypeRatingsSection > .TypeRating span {
  /*These styles along with the width property on TypeRating is used to center the TypeRatingsSection with the Pill component above*/
  overflow: hidden;
  white-space: nowrap;
}

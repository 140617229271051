.HeaderSelectOption {
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: var(--spacing-sl);
}

.HeaderSelectOption > svg {
  width: 24px;
  height: 24px;
}

.InformationBox {
  padding: var(--spacing-ml) var(--spacing-ml);
  display: flex;
}

.InformationBoxColumn {
  flex-direction: column;
}

.InformationBoxRow {
  flex-direction: row;
}

.InformationBoxColumn {
  flex-direction: column;
}

.InformationBoxRow {
  flex-direction: row;
  align-self: center;
}

.InformationBoxRoundedCorners {
  border-radius: 4px;
}

.InformationBoxSuccess {
  background-color: var(--color-green-20);
}

.InformationBoxAlert {
  background-color: var(--color-yellow-20);
}

.InformationBoxInfo {
  background-color: var(--color-grey-20);
}

.InformationBoxPilotproject {
  background-color: rgba(var(--color-coral-20-rgb), 0.5);
}

.InformationBox .InformationBoxHeaderArea {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: var(--spacing-m);
  margin-bottom: var(--spacing-s);
}

.InformationBox .InformationBoxNoChildren {
  margin-bottom: 0;
}

.InformationBox .InformationBoxIconContainer {
  display: flex;
}

.InformationBox .InformationBoxIconContainer svg {
  width: 24px;
  height: 24px;
}

.InformationBox .InformationBoxTextArea {
  /* var(--spacing-ll): 40px = svg: 24px + gap: 16px */
  margin-left: var(--spacing-ll);
  text-align: left;
}

.InformationBox .InformationBoxTextArea > ul {
  padding: 0;
  margin: 0;
  /* var(--spacing-m): 16px to keep the bullet point centered, as it's centre is approx 8px away from text */
  margin-left: var(--spacing-m);
}

.InformationBox .InformationBoxWithoutHeaderArea {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: var(--spacing-m);
}

.InformationBox .EditButton {
  margin-left: auto;
}

.InformationBox .EditButton p {
  text-decoration: underline;
  color: var(--color-blue);
  cursor: pointer;
}

.InformationBox .EditButton:hover p {
  text-decoration: underline;
  color: var(--color-blue-60);
}

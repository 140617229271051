.PublicFooter {
  padding: 0 var(--spacing-4xl) 0 var(--spacing-4xl);
  background-color: var(--color-light-grey);
}

.PublicFooter .FooterWrapper {
  display: flex;
  justify-content: center;
  padding: var(--spacing-ll) 0;
}

.PublicFooter .FooterWrapper .Footer {
  width: 100%;
  max-width: 1760px;
  margin-left: var(--spacing-4xl);
}

@media only screen and (min-width: 1864px) {
  .PublicFooter .FooterWrapper .Footer {
    margin-left: calc(3 * var(--spacing-4xl));
  }
}

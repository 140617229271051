.Pill {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  border-radius: 15px;
  padding: 0 var(--spacing-ml);
}

.Pill p {
  font-weight: 700;
  white-space: nowrap;
}

.PillGreen {
  background-color: var(--color-green-20);
}

.PillGreen p {
  color: var(--color-green);
}

.PillGreen.Link:hover p {
  color: var(--color-green-80);
}

.PillCoral {
  background-color: var(--color-coral-20);
}

.PillCoral p {
  color: var(--color-coral);
}

.PillCoral.Link:hover p {
  color: var(--color-coral-80);
}

.PillBlue {
  background-color: var(--color-iblue-20);
}

.PillBlue p {
  color: var(--color-iblue);
}

.PillBlue.Link:hover p {
  color: var(--color-iblue-80);
}

.PillCyan {
  background-color: var(--color-cyan-20);
}

.PillCyan p {
  color: var(--color-cyan);
}

.PillCyan.Link:hover p {
  color: var(--color-cyan-80);
}

.PillPink {
  background-color: var(--color-pink-20);
}

.PillPink p {
  color: var(--color-pink);
}

.PillPink.Link:hover p {
  color: var(--color-pink-80);
}

.PillPurple {
  background-color: var(--color-purple-20);
}

.PillPurple p {
  color: var(--color-purple);
}

.PillPurple.Link:hover p {
  color: var(--color-purple-80);
}

.PillRed {
  background-color: var(--color-red-20);
}

.PillRed p {
  color: var(--color-red);
}

.PillRed.Link:hover p {
  color: var(--color-red-80);
}

.PillYellow {
  background-color: var(--color-yellow-20);
}

.PillYellow p {
  color: var(--color-yellow-dark);
}

.PillYellow.Link:hover p {
  color: var(--color-yellow-dark-80);
}

.PillGrey {
  background-color: var(--color-grey-20);
}

.PillGrey p {
  color: var(--color-grey-60);
}

.PillGrey.Link:hover p {
  color: var(--color-grey-40);
}

.PillGrey-clear {
  background-color: none;
  border: 1px solid var(--color-grey-80);
}

.PillGrey-clear p {
  color: var(--color-grey-80);
}

.PillGrey-clear.Link:hover p {
  color: var(--color-grey-60);
}

.PillDark-green {
  background-color: var(--color-green);
}

.PillDark-green p {
  color: var(--color-white);
}

.PillDark-green.Link:hover p {
  /*Used as a lighter shade of white*/
  color: var(--color-cyan-20);
}

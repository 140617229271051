.IconPill {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 22px;
  border-radius: 15px;
  border: 1px solid;
  gap: var(--spacing-s);
  padding: 0 var(--spacing-s);
}

.IconPill p {
  white-space: nowrap;
  line-height: normal;
}

.IconPill .IconPillIcon {
  height: 14px;
  width: 14px;
  display: flex;
}

.IconPill .IconPillIcon svg {
  height: 100%;
  width: 100%;
}

.IconPillCoral {
  border-color: var(--color-coral);
}

.IconPillCoral p {
  color: var(--color-coral);
}

.IconPillCoral.Link:hover p {
  color: var(--color-coral-80);
}

.RemainingTimeContainer {
  display: flex;
  column-gap: var(--spacing-s);
  align-items: center;
  height: 100%;
}

.RemainingTimeContainer > h3 {
  margin: 0;
  font-size: 14px;
}

.RemainingTimeContainer.High {
  color: var(--color-coral);
}

.RemainingTimeContainer.Low > svg > circle {
  fill: var(--color-green);
}

.RemainingTimeContainer.Medium > svg > circle {
  fill: var(--color-yellow);
}

.RemainingTimeContainer.Complete {
  color: var(--color-green);
}
.RemainingTimeContainer.Complete > svg > circle {
  fill: var(--color-green);
}

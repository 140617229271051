.SliderContainer {
  width: 100%;
  padding-bottom: 26px;
}

.SliderContainer > span {
  display: inline-block;
  margin-bottom: var(--spacing-sl);
  font-weight: 700;
  color: var(--color-grey);
}

.Slider.rc-slider {
  position: relative;
  width: calc(100% - 16px);
  height: 16px;
  padding: var(--spacing-xs) 0;
  margin-left: var(--spacing-s);
  border-radius: 6px;
  touch-action: none;
  box-sizing: border-box;
}

.Slider.rc-slider * {
  box-sizing: border-box;
}

.Slider .rc-slider-rail {
  position: absolute;
  width: 100%;

  /*Kana styles*/
  height: 8px;
  background-color: var(--color-blue-20);
  border-radius: 4px;
}

.Slider .rc-slider-track {
  position: absolute;

  /*Kana styles*/
  height: 8px;
  background-color: var(--color-blue);
  border-radius: 4px;
}

.Slider .rc-slider-handle {
  position: absolute;
  border-radius: 50%;
  cursor: grab;
  touch-action: pan-x;

  /*Kana styles*/
  width: 16px;
  height: 16px;
  margin-top: -4px;
  background-color: var(--color-white);
  border: 2px solid var(--color-blue);
}

.Slider .rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
  /*Kana styles*/
  box-shadow: 0 0 0 var(--spacing-s) rgba(var(--color-blue-rgb), 20%);
}

.Slider .rc-slider-handle:hover {
  /*Kana styles*/
  border: 2px solid var(--color-blue);
  box-shadow: 0 0 0 var(--spacing-s) rgba(var(--color-blue-rgb), 20%);
}

.Slider .rc-slider-handle:focus-visible {
  /*Kana styles*/
  outline: 2px dashed var(--color-grey-40);
  outline-offset: var(--spacing-xs);
}

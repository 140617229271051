.CoreCarbonPrinciples {
  padding: var(--spacing-2xl) var(--spacing-3xl) var(--spacing-2xl) var(--spacing-2xl);
  background-color: var(--color-white);
}

.CoreCarbonPrinciples .CoreCarbonPrinciplesLink a {
  text-decoration: underline;
  color: var(--color-blue);
}

.CoreCarbonPrinciples .CoreCarbonPrinciplesLink svg {
  margin-right: var(--spacing-s);
}

.CoreCarbonPrinciples .PrincipleNotMet path {
  fill: var(--color-grey-20);
}

.CoreCarbonPrinciples .PrincipleNotMetAlt path {
  stroke: var(--color-grey-20);
}

.CoreCarbonPrinciples .CoreCarbonPrinciplesHeader {
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--spacing-2xl);
}

.CoreCarbonPrinciples .CoreCarbonPrinciplesHeader div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--spacing-s);
}

.CoreCarbonPrinciples .CoreCarbonPositionsTable {
  border: 1px solid var(--color-grey-40);
  border-radius: 8px;
}

.CoreCarbonPrinciples .CoreCarbonPrincipleTitle {
  display: flex;
  gap: var(--spacing-m);
  padding-right: var(--spacing-m);
}

.CoreCarbonPrinciples .CoreCarbonPosition {
  display: flex;
  border-bottom: 1px solid var(--color-grey-40);
}

.CoreCarbonPrinciples .CoreCarbonPosition .CoreCarbonPositionTitle {
  flex: 4;
  padding: var(--spacing-ml) var(--spacing-m) var(--spacing-ml) var(--spacing-ml);
  border-right: 1px solid var(--color-grey-40);
  font-weight: 700;
}

.CoreCarbonPrinciples .CoreCarbonPosition .CoreCarbonPositionDetail {
  flex: 8;
  padding: var(--spacing-ml);
  white-space: pre-line;
}

.CoreCarbonPrinciples .CoreCarbonPosition .CoreCarbonPositionNoDetail {
  color: var(--color-grey-20);
}

.CoreCarbonPrinciples .CoreCarbonPositionsTable .CoreCarbonPosition:last-child {
  border-bottom: 0;
}

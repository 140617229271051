.ProfileMenuItem {
  border: none;
  background: none;
  color: var(--color-grey);
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  padding: 0 var(--spacing-m);
}

.ProfileMenuItem > svg {
  padding: 0 var(--spacing-s) 0 0;
}

.ProfileMenuItem p {
  margin: 0;
}
.ProfileMenuItemSelected p {
  font-weight: bold;
}

.ProfileMenuItem:hover {
  background-color: var(--color-light-grey);
  color: var(--color-grey);
}

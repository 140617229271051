.ActivityWizardContainer_Review {
  flex-direction: row;
}

.ActivityWizardContainer_Review .UserHeader .Button_secondary {
  justify-content: left;
}

.ActivityWizardContainer .ActivityWizardContainerContentUserHeader,
.ActivityWizardContainer .ActivityWizardContainerStepName,
.ActivityWizardContainer .ActivityWizardContainerStepper {
  padding-left: var(--spacing-4xl);
  padding-right: var(--spacing-4xl);
}

.ActivityWizardReviewStepContainer,
.ActivityWizardContainerContent .ActivityWizardContainerContentStepName,
.ActivityWizardContainerContent .ActivityWizardContainerStepper {
  padding-left: var(--spacing-4xl);
  padding-right: var(--spacing-4xl);
}

.ActivityWizardReviewStepContainer .Review .IsReviewValidSection {
  padding-top: var(--spacing-ll);
}

.ActivityWizardReviewStepContainer .IsReviewValidSection p {
  padding-bottom: var(--spacing-m);
}

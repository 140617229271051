.ActivityWizardContainer {
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  height: 100%;
}

.ActivityWizardContainer .ActivityWizardContainerContent {
  min-width: 0;
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: var(--color-light-grey);
}

.AssetManager.ActivityWizardContainer .ActivityWizardContainerContent {
  background-color: var(--color-white);
}

.ActivityWizardContainer .ActivityWizardContainerWizard {
  display: flex;
  flex-direction: column;
  min-width: 320px;
}

.ActivityWizardContainer .ActivityWizardContainerContentActions {
  display: flex;
  gap: var(--spacing-ml);
  padding-left: var(--spacing-4xl);
  padding-right: var(--spacing-4xl);
}

.ProgressIndicator {
  width: 100%;
  position: relative;
}

.ProgressIndicator .Disabled {
  pointer-events: none;
  opacity: 0.2;
  transition: all 0.2s;
}

.ProgressIndicator .Enabled {
  opacity: 1;
  transition: all 0.2s;
}

.ProgressIndicator .AdditionalActivityCoralIcon {
  position: absolute;
  top: -36px;
  left: 0px;
}

.ProgressIndicator .AdditionalActivityCoralIcon.Disabled {
  opacity: 0;
  transition: all 0.2s;
}

.ProgressIndicator .AdditionalActivityCoralIcon.Enabled {
  opacity: 1;
  transition: all 0.2s;
}

.ProgressIndicator.Activity {
  position: relative;
}

.ProgressIndicator .ActivityStatusContainer .ActivityDisplayName {
  display: flex;
  flex-direction: row;
  column-gap: calc(var(--spacing-xs) + var(--spacing-xxs));
  align-items: center;
}

.ProgressIndicator.Activity .ActivityStatusContainer {
  padding-bottom: var(--spacing-m);
}

.ProgressIndicator .ProgressIndicatorTopRightContainer {
  display: flex;
  align-items: center;
  position: absolute;
  top: -48px;
  right: var(--spacing-m);
  height: 44px;
}

.ProgressIndicator .ActivityStatusContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.ProgressIndicator .ActivityStatusContainer .CompleteActivityMessage,
.ProgressIndicator .ActivityStatusContainer .CancelledActivityMessage {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: var(--spacing-s);
  position: relative;
  top: var(--spacing-ml);
  padding-bottom: var(--spacing-ml);
}

.ProgressIndicator .ActivityStatusContainer .ChangesRequiredMessage {
  position: relative;
  top: var(--spacing-m);
  padding-bottom: var(--spacing-s);
}
.ProgressIndicator .ActivityStatusContainer .ChangesRequiredMessage .InformationBox {
  padding: var(--spacing-s);
  max-width: fit-content;
}
.ProgressIndicator .ActivityStatusContainer .ChangesRequiredMessage .InformationBox .InformationBoxWithoutHeaderArea {
  align-items: center;
}

.ProgressIndicator .ActivityStatusContainer > p {
  color: var(--color-grey);
}

.ProgressIndicator .ActivityStatusContainer .ActivityStatusBar {
  display: flex;
  flex-direction: row;
  height: 12px;
  margin-top: var(--spacing-m);
  margin-bottom: var(--spacing-s);
}

.ProgressIndicator .ActivityStatusContainer .ActivityStatusBar .ActivityStatusBarFilled {
  background-color: var(--color-green);
}

.ProgressIndicator .ActivityStatusContainer .ActivityStatusBar .ActivityStatusBarUnfilled {
  background-color: var(--color-green-20);
}

.ProgressIndicator .ButtonContainer {
  margin-top: var(--spacing-ml);
  display: flex;
  column-gap: var(--spacing-m);
  align-items: center;
}

.ProgressIndicator .ButtonContainer .DownloadDarkIcon {
  padding-left: var(--spacing-s);
}

.ProgressIndicator .ButtonContainer .Button {
  padding: var(--spacing-sl) var(--spacing-sl) var(--spacing-sl) var(--spacing-sl);
  min-width: 80px;
}

@media only screen and (min-width: 1864px) {
  .ProgressIndicator .ButtonContainer {
    column-gap: var(--spacing-ml);
  }
}

.ProgressIndicator .BtnLink {
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
  display: flex;
  font-weight: 700;
  color: var(--color-blue);
  text-decoration: underline;
  margin-left: var(--spacing-xs);
}

.Empty.ProgressIndicator {
  color: var(--color-grey-40);
}

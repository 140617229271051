.ProjectsSection {
  background-color: var(--color-white);
  padding: var(--spacing-2xl) var(--spacing-2xl) 0;
  margin-bottom: var(--spacing-ml);
  box-shadow: 0 0 var(--box-shadow-spread) var(--color-box-shadow);
}

.ProjectsSection .DataGridButton .Button span {
  text-decoration: underline;
  font-size: 14px;
}

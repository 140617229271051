.SingleFileReplaceWrapper {
  position: relative;
}

.SingleFileReplaceWrapper {
  display: flex;
  flex-direction: column;
  row-gap: var(--spacing-m);
}

.SingleFileReplaceWrapper .SingleFileReplace {
  display: flex;
  flex-direction: column;
  row-gap: var(--spacing-s);
  width: 100%;
}

.SingleFileReplaceWrapper .SingleFileReplace .TextWithLoadingSpinner {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: var(--spacing-s);
}

.SingleFileReplaceWrapper .SingleFileReplace .CurrentUploadedFile {
  display: flex;
  align-items: center;
  column-gap: var(--spacing-s);
  cursor: pointer;
  justify-content: space-between;
  width: 100%;
}

.SingleFileReplaceWrapper .SingleFileReplace .CurrentUploadedFile .CurrentUploadedFileName {
  display: flex;
  align-items: center;
  gap: var(--spacing-s);
  width: 100%;
}

.SingleFileReplaceWrapper .SingleFileReplace .CurrentUploadedFile .CurrentUploadedFileName > h3 {
  color: var(--color-blue);
  text-decoration: underline;
  overflow: hidden;
  text-overflow: ellipsis;
}

.SingleFileReplaceWrapper .SingleFileReplace .CurrentUploadedFile .FileLinesBlueIcon {
  min-width: 12px;
}
.SingleFileReplaceWrapper .SingleFileReplace .CurrentUploadedFile .DropdownLightIcon {
  min-width: 10px;
}

.SingleFileReplaceWrapper .SingleFileReplace .CurrentUploadedFile .DropdownLightIcon > path {
  stroke: var(--color-blue);
}

.SingleFileReplaceWrapper .SingleFileReplace .CurrentUploadedFile .CurrentUploadedFileName:hover > h3,
.SingleFileReplaceWrapper
  .SingleFileReplace
  .CurrentUploadedFile
  .CurrentUploadedFileName:hover
  .FileLinesBlueIcon
  > path {
  color: var(--color-blue-60);
  fill: var(--color-blue-60);
}
.SingleFileReplaceWrapper .SingleFileReplace .CurrentUploadedFile:hover .DropdownLightIcon > path {
  stroke: var(--color-blue-60);
}

.SingleFileReplaceWrapper .SingleFileReplace .CurrentUploadedFile:active > h3,
.SingleFileReplaceWrapper .SingleFileReplace .CurrentUploadedFile:active .FileLinesBlueIcon > path {
  color: var(--color-blue-80);
  fill: var(--color-blue-80);
}
.SingleFileReplaceWrapper .SingleFileReplace .CurrentUploadedFile:active .DropdownLightIcon > path {
  stroke: var(--color-blue-80);
}

.SingleFileReplaceWrapper .Dropdown {
  position: absolute;
  right: 70px;
  top: 80px;
  display: flex;
  flex-direction: column;
  row-gap: var(--spacing-m);
  background-color: var(--color-white);
  white-space: nowrap;
  box-shadow: 0 0 var(--box-shadow-spread) var(--color-box-shadow);
  z-index: 2;
  padding: var(--spacing-m);
  width: fit-content;
  height: fit-content;
}

.SingleFileReplaceWrapper .Dropdown .DropdownButton {
  display: flex;
  flex-direction: row;
  column-gap: var(--spacing-s);
  align-items: center;
  cursor: pointer;
}

.SingleFileReplaceWrapper .Dropdown .DropdownButton .TrashDarkIcon > path {
  stroke: var(--color-blue);
}
.SingleFileReplaceWrapper .Dropdown .DropdownButton:hover .TrashDarkIcon > path {
  stroke: var(--color-blue-60);
}
.SingleFileReplaceWrapper .Dropdown .DropdownButton:active .TrashDarkIcon > path {
  stroke: var(--color-blue-80);
}

.SingleFileReplaceWrapper .Dropdown .DropdownButton:hover,
.SingleFileReplaceWrapper .Dropdown .DropdownButton:hover .UploadBlueIcon > path {
  color: var(--color-grey-60);
  fill: var(--color-blue-60);
}
.SingleFileReplaceWrapper .Dropdown .DropdownButton:active,
.SingleFileReplaceWrapper .Dropdown .DropdownButton:active .UploadBlueIcon > path {
  color: var(--color-grey-80);
  fill: var(--color-blue-80);
}

.SingleFileReplaceWrapper .SingleFileReplaceActions {
  display: flex;
  align-items: center;
  gap: var(--spacing-l);
  justify-content: space-between;
}

.SingleFileReplaceWrapper .SingleFileReplaceActions .SingleFileReplacePreviewBtn .Button {
  padding: 0;
  min-height: 50px;
  height: 50px;
}

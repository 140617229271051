.LogoContainerLabel {
  display: block;
}

.LogoContainer {
  width: 136px;
  height: 136px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid var(--color-light-grey);
}

.LogoContainer img {
  /*From the img width and height we must substract the borders  */
  max-height: 132px;
  max-width: 132px;
}

/* Header */
.DiscussionThreadHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: var(--spacing-m);
}
.DiscussionThreadHeader .DiscussionSelectContainer {
  display: flex;
  flex-direction: row;
  gap: var(--spacing-m);
}
/* Internal team only */
.DiscussionThreadHeader .DiscussionInternalTeamOnly > p {
  color: var(--color-blue);
  font-weight: 700;
}

/* Discussion Select Item */
.DiscussionThreadHeader .DiscussionSelectItem {
  padding-bottom: var(--spacing-s);
  cursor: pointer;
}
.DiscussionThreadHeader .DiscussionSelectItem .DiscussionTitle {
  color: var(--color-blue);
}

/* Discussion Select Item - Selected Variant */
.DiscussionThreadHeader .DiscussionSelectItem.Selected {
  border-bottom: 2px solid var(--color-blue);
}
.DiscussionThreadHeader .DiscussionSelectItem.Selected .DiscussionTitle {
  font-weight: bold;
}

/* Horizontal Divider */
.DiscussionCard .Divider_horizontal {
  width: auto;
  height: 1px;
  background-color: var(--color-grey-20);
}

/* Discussion Thread Body */
.DiscussionThreadBody {
  margin-top: var(--spacing-m);
  margin-right: var(--spacing-s);
  gap: var(--spacing-ml);
}

.DiscussionThreadBody .ResolvedContainer .ResolvedHeader :not(.Divider_horizontal),
.DiscussionThreadBody .ResolvedContainer .DiscussionMessageHeader > div:not(.DiscussionSelectActions),
.DiscussionThreadBody .ResolvedContainer .DiscussionMessageBody {
  opacity: 30%;
}

.DiscussionThreadBody .ResolvedContainer .ResolvedHeader {
  display: flex;
  align-items: center;
  column-gap: 8px;
  margin: var(--spacing-ml) 0 var(--spacing-m);
}

.DiscussionThreadBody .ResolvedContainer .ResolvedHeader .Divider_horizontal {
  width: 100%;
}

.DiscussionThreadBody .DiscussionFirstMessageContainer {
  margin-bottom: var(--spacing-ml);
}

.DiscussionThreadBody .ReplyContainer {
  display: flex;
  gap: var(--spacing-ml);
  margin-bottom: var(--spacing-ml);
}

.DiscussionThreadBody .ReplyContainer .Divider_vertical {
  height: auto;
  width: 1px;
  background-color: var(--color-grey-20);
}

.DiscussionThreadBody .DiscussionMessageReplies {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: var(--spacing-ml);
}

.DiscussionThreadBody .DiscussionNoMessages {
  padding-bottom: var(--spacing-2xl);
}

.NewDiscussionContainer,
.DiscussionNewReplyContainer {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-m);
}

.NewDiscussionContainer {
  padding: var(--spacing-s) 0 var(--spacing-xs) 0;
}

.NewDiscussionButton {
  padding-bottom: var(--spacing-xs);
}

.TextAreaLabelContainer {
  display: flex;
  justify-content: space-between;
}

.TextAreaLabelContainer span {
  margin-left: var(--spacing-xs);
  color: var(--color-grey-40);
}

.TextArea textarea {
  border: 1px solid var(--color-grey-20);
  padding: var(--spacing-m);
  resize: none;
  width: 100%;
  min-height: 120px;
}

.TextArea_resizable textarea {
  resize: both;
}

.TextArea_disabled label {
  color: var(--color-grey-40);
}

.TextArea_disabled textarea {
  background-color: var(--color-light-grey);
  resize: none;
}

.TextAreaCharacterCount {
  width: 100%;
  display: flex;
  margin-top: var(--spacing-s);
  justify-content: flex-end;
  color: var(--color-grey-40);
}

.Pagination {
  display: flex;
  list-style-type: none;
  justify-content: center;
  padding: 0;
}

.PaginationItem {
  border: none;
  background: none;
  padding: 0;

  margin: auto calc(var(--spacing-xs) / 2);
  color: var(--color-grey);
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;

  height: 32px;
  min-width: 32px;
  left: 36px;
  border-radius: 8px;

  font-family: var(--font-family-default-w-apple);
  font-size: var(--font-size-body2);
  font-weight: 400;
  line-height: var(--line-height-h3);
  letter-spacing: 0px;
  text-align: center;
}

.PaginationItem.Dots:hover {
  background-color: transparent;
  cursor: default;
}
.PaginationItem:hover {
  background-color: var(--color-grey-20);
  cursor: pointer;
}

.PaginationItem:focus-visible {
  outline: none;
  border: 1px dashed var(--color-grey-40);
}

.PaginationItem.selected {
  background-color: var(--color-blue-20);
  font-weight: 700;
}

.PaginationItem.disabled {
  pointer-events: none;
}

.PaginationItem.disabled svg path {
  stroke: var(--color-grey-40);
}
.PaginationItem.disabled:hover {
  background-color: transparent;
  cursor: default;
}

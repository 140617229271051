.AuthenticationScreen {
  min-height: 100vh;
  display: flex;
  min-width: 1024px;
  margin-right: auto;
  margin-left: auto;
}

@media only screen and (min-width: 768px) {
  .AuthenticationContainer {
    /* We don't have a horizontal width variable for 40px */
    padding-left: var(--spacing-ll);
  }
}

@media only screen and (min-width: 1024px) {
  .AuthenticationContainer {
    /* We don't have a horizontal width variable for 56px */
    padding-left: var(--spacing-2xl);
  }
}

@media only screen and (min-width: 1440px) {
  .AuthenticationContainer {
    padding-left: var(--spacing-4xl);
  }
}

/* Removing margin if screen width is 80px larger on each side than the max-width set of 1600px*/
@media only screen and (min-width: 1760px) {
  .AuthenticationContainer {
    padding-left: 0;
    margin-left: calc((100vw / 2) - 800px);
  }
}

.AuthenticationContainer {
  display: flex;
  flex-direction: column;
  width: 50%;
  min-width: 512px; /* 1024px/2 */
  max-width: 800px;
}

.AuthenticationContent {
  display: flex;
  flex-direction: column;
  margin-top: calc(20vh - var(--spacing-ml) - var(--spacing-2xl));
  margin-bottom: var(--spacing-2xl);
}

.AuthenticationLogo {
  margin-top: var(--spacing-2xl);
}

.AuthenticationFooter {
  color: var(--color-grey-40);
  margin-top: auto;
  margin-bottom: var(--spacing-2xl);
}

.AuthenticationImage {
  position: fixed;
  background-position: center center;
  background-size: cover;
  left: 50%;
  width: 50%;
  height: 100%;
}

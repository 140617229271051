.Tag {
  display: inline-flex;
  justify-content: center;
  border-radius: 15px;
  padding: var(--spacing-s) var(--spacing-s) var(--spacing-s) var(--spacing-m);
  background-color: var(--color-green-20);
}

.TagWithoutIcon {
  padding-right: var(--spacing-m);
}

.Tag > span {
  vertical-align: middle;
  color: var(--color-grey);
}

.Tag > svg {
  width: 12px;
  height: 12px;
  padding-right: var(--spacing-s);
  padding-left: var(--spacing-s);
  cursor: pointer;
}

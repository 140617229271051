.SingleFileDropdownUpload {
  position: relative;
  display: flex;
}

.SingleFileDropdownUpload .SingleFileDropdownUploadContent {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.SingleFileDropdownUpload .SingleFileDropdownUploadContentInput {
  display: flex;
}

.SingleFileDropdownUploadInputWrapper {
  width: 100%;
}

.SingleFileDropdownUploadInputWrapper > input {
  display: none;
}

.SingleFileDropdownUploadInputWrapper .FileSize {
  color: var(--color-grey-40);
}

.SingleFileDropdownUploadInputWrapper_disabled > label {
  color: var(--color-grey-40);
}

.SingleFileDropdownUploadInputWrapper_disabled .SingleFileDropdownUploadInputFileBox {
  background-color: transparent;
}

.SingleFileDropdownUploadInputWrapper_disabled .SingleFileDropdownUploadInputBtn,
.SingleFileDropdownUploadInputWrapper_disabled .SingleFileDropdownUploadInputBtn:hover {
  cursor: default;
  background-color: var(--color-grey-40);
  color: var(--color-grey-20);
}

.SingleFileDropdownUploadInput {
  display: flex;
  margin-top: var(--spacing-s);
}

.SingleFileDropdownUploadInput:focus-visible {
  /*Kana styles*/
  outline: 1px dashed var(--color-grey-40);
  outline-offset: var(--spacing-xs);
}

.SingleFileDropdownUploadInputFileBox {
  display: inline-block;
  background-color: var(--color-white);
  width: 100%;
  min-width: 100px;
  border: 1px solid var(--color-grey-20);
  padding-left: var(--spacing-m);
  padding-right: var(--spacing-m);

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.SingleFileDropdownUploadInputFileBox:after {
  content: "";
  display: inline-block;
  vertical-align: middle;
  height: 100%;
}

.SingleFileDropdownUploadInputBtn {
  display: flex;
  column-gap: var(--spacing-s);
  justify-content: center;
  align-items: center;
  padding: var(--spacing-m);
  cursor: pointer;
  white-space: nowrap;
  color: var(--color-white);
  background: var(--color-blue);
  border: none;
  min-width: 136px;
}

.SingleFileDropdownUploadInputBtn.TextWithLoadingSpinner {
  user-select: none;
}

.SingleFileDropdownUploadInputBtn.TextWithLoadingSpinner > p {
  color: var(--color-white);
}

.SingleFileDropdownUploadInputBtn > svg > path {
  stroke: var(--color-white);
}

.SingleFileDropdownUploadInputBtn:hover {
  background: var(--color-blue-60);
}

.SingleFileDropdownUploadInputBtn:active {
  background: var(--color-blue-80);
}

.SingleFileDropdownUpload .DropdownWrapper {
  position: absolute;
  right: 0px;
  top: 86px;
  display: flex;
  flex-direction: column;
  background-color: var(--color-white);
  box-shadow: 0 0 var(--box-shadow-spread) var(--color-box-shadow);
  z-index: 2;
  width: fit-content;
  height: fit-content;
}

.SingleFileDropdownUpload .DropdownWrapper .DropdownGroup {
  display: flex;
  flex-direction: column;
  row-gap: var(--spacing-m);
  white-space: nowrap;
  padding: var(--spacing-m);
  width: fit-content;
  height: fit-content;
}

.SingleFileDropdownUpload .DropdownWrapper .DropdownItem {
  display: flex;
  column-gap: var(--spacing-s);
  cursor: pointer;
  width: fit-content;
}

.SingleFileDropdownUpload .DropdownWrapper .DropdownItem:hover > svg > path,
.SingleFileDropdownUpload .DropdownWrapper .DropdownItem:hover {
  color: var(--color-grey-60);
  fill: var(--color-blue-60);
}

.SingleFileDropdownUpload .DropdownWrapper .DropdownItem:active > svg > path,
.SingleFileDropdownUpload .DropdownWrapper .DropdownItem:active {
  color: var(--color-grey-80);
  fill: var(--color-blue-80);
}

.SingleFileDropdownUpload .DropdownWrapper .DropdownItem:focus-visible,
.SingleFileDropdownUpload .DropdownWrapper .DropdownItem:focus-within {
  /*Kana styles*/
  outline: 1px dashed var(--color-grey-40);
  outline-offset: var(--spacing-xs);
}

.SingleFileDropdownUpload .DropdownWrapper .DropdownItem:hover svg.TrashDarkIcon path {
  stroke: var(--color-blue-60);
  fill: none;
}

.SingleFileDropdownUpload .DropdownWrapper .DropdownItem:active svg.TrashDarkIcon path {
  stroke: var(--color-blue-80);
  fill: none;
}

.SingleFileDropdownUploadPreview {
  margin-left: var(--spacing-2xl);
}

.SingleFileDropdownUploadPreview .LogoContainer {
  margin-top: var(--spacing-m);
}

.SingleFileDropdownUpload .Upload:focus-visible {
  /*Kana styles*/
  outline: 1px dashed var(--color-grey-40);
  outline-offset: var(--spacing-xs);
}

.SingleFileDropdownUpload .SingleFileDropdownUploadPreviewBtn {
  position: relative;
  bottom: 8px;
  display: flex;
  align-items: center;
  margin-left: var(--spacing-s);
  justify-content: center;
}

.SingleFileDropdownUpload .SingleFileDropdownUploadPreviewBtn .Button {
  padding: 0;
  min-height: 50px;
  height: 50px;
}

.SingleFileDropdownUpload .DropdownItemWrapper {
  display: flex;
  justify-content: space-between;
}

.SingleFileDropdownUpload .DropdownItemWrapper .DropdownItem span {
  max-width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.SingleFileDropdownUpload .SingleFileDropdownUploadItemPreview {
  display: flex;
  margin-left: var(--spacing-s);
  align-items: center;
  gap: var(--spacing-s);
}

.SingleFileDropdownUpload .SingleFileDropdownUploadItemPreview svg:hover {
  cursor: pointer;
}

.SingleFileDropdownUpload .SingleFileDropdownUploadItemPreview .Divider_vertical {
  background-color: var(--color-grey-20);
}

.SingleFileDropdownUpload .SingleFileDropdownUploadItemPreview .SingleFileDropdownUploadItemPreviewBtn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.SingleFileDropdownUpload .SingleFileDropdownUploadItemPreview .EyeBlueIcon:hover path {
  stroke: var(--color-blue-40);
}

.SingleFileDropdownUpload .SingleFileDropdownUploadItemPreview .DownloadDarkIcon:hover path {
  fill: var(--color-blue-40);
}

.SingleFileDropdownUpload .SingleFileDropdownUploadItemPreview div:focus-visible {
  outline: 1px dashed var(--color-grey-40);
  outline-offset: var(--spacing-xs);
}

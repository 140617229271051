.BasicDetailsSection_ValidatorContainer .ImageContainer {
  padding-left: var(--spacing-sl);
}

.BasicDetailsSection_ValidatorContainer .ImageContainer > img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid var(--color-grey-20);
}

.BasicDetailsSection_ValidatorContainer .ValidatorNameInDeveloperRoute {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: var(--spacing-sl);
  font-weight: 700;
}
.BasicDetailsSection_ValidatorContainer .Button {
  min-width: unset;
}

.NoData {
  color: var(--color-grey-60);
}

.Stepper {
  display: flex;
  align-items: center;
  gap: var(--spacing-s);
  flex-wrap: wrap;
}

.Stepper .StepperStep {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.Stepper .StepDivider {
  flex-grow: 1;
  max-width: 80px;
  min-width: 24px;

  border: 1px solid;
  border-radius: 2px;
  border-color: var(--color-green);
}

.Stepper .StepperStep p {
  white-space: nowrap;
  margin-left: var(--spacing-s);
}

.Stepper .StepperStep .StepIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.Stepper .StepperStep .StepIcon .StepNumber {
  font-family: var(--font-family-body2);
  font-weight: 700;
  font-size: var(--font-size-body2);
  line-height: 18px;
  position: absolute;
  color: var(--color-white);
}

.Stepper .StepDivider.NotStarted {
  border-color: var(--color-blue-20);
}

.Stepper .StepperStep.NotStarted p {
  color: var(--color-grey-60);
}

.Stepper .StepDivider.Invalid {
  border-color: var(--color-red);
}
.Stepper .StepDivider.Invalid.Verifier {
  border-color: var(--color-yellow-dark-80);
}

.Stepper .StepDivider.Warning {
  border-color: var(--color-red);
}
.Stepper .StepDivider.Warning.Developer {
  border-color: var(--color-yellow-dark-80);
}

.Stepper .StepperStep.InProgress p,
.Stepper .StepperStep.IsCurrent p {
  font-weight: 700;
}

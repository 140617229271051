.ActivityWizardContainer_Edit {
  flex-direction: row;
}

.ActivityWizardContainer_Edit .UserHeader .Button_secondary {
  justify-content: left;
}

.SaveCloseArrow path {
  fill: var(--color-blue);
}

.ActivityWizardContainer .ActivityWizardContainerContentUserHeader,
.ActivityWizardContainer .ActivityWizardContainerStepName,
.ActivityWizardContainer .ActivityWizardContainerStepper {
  padding-left: var(--spacing-4xl);
  padding-right: var(--spacing-4xl);
}

.ActivitiesDetailsButtonWrapper {
  width: 100%;
  height: 100%;
}

.ActivitiesDetailsButton {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.ActivitiesDetailsButton > svg {
  width: 12px;
  height: 12px;
}

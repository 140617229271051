.HideDiscussionButton {
  text-decoration: underline;
  padding-left: 0px;
}

.DiscussionViewWrapper .DataGridContainer div[role="grid"] {
  height: auto!important;
}

.DiscussionViewWrapper.hidden {
  display: none
}

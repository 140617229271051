.RatingContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: var(--spacing-l);
  height: 100%;
  width: 100%;
  padding: var(--spacing-l);
  background-color: var(--color-white);
}

.RatingContainer .ScoreSection {
  display: flex;
  flex-direction: row;
  justify-content: center;
  column-gap: var(--spacing-s);
}

.RatingContainer .ScoreSection svg.Grey > path {
  fill: var(--color-grey-20);
}

.RatingContainer h3 {
  color: var(--color-blue);
  font-size: 14px;
}

.DeleteDocumentVersionCellFormatter {
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.DeleteDocumentVersionCellFormatter > svg {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.DeleteDocumentVersionCellFormatter > svg > path {
  stroke: var(--color-blue);
  stroke-width: 1.5;
}

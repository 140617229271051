.Dashboard .ProjectInvitationNotificationContent {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.Dashboard .ProjectInvitationNotificationContent .BtnLink {
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
  display: flex;
  font-weight: 700;
  color: var(--color-blue);
  text-decoration: underline;
}

.Dashboard .HeaderContainer {
  display: flex;
  justify-content: space-between;
  margin-top: var(--spacing-l);
}

.Dashboard .HeaderContainer > h1 {
  margin-top: calc(-1 * var(--spacing-m));
}

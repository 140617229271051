.ActivityReviewProgressIndicator {
  width: 100%;
  position: relative;
}

.ActivityReviewProgressIndicator .ManageActivityReviewStatusContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.ActivityReviewProgressIndicator .ActivityReviewStatusContainer .ActivityDisplayName {
  display: flex;
  flex-direction: row;
  column-gap: calc(var(--spacing-xs) + var(--spacing-xxs));
  align-items: center;
}

.ActivityReviewProgressIndicator .ActivityReviewStatusContainer h3 {
  margin: var(--spacing-m) 0;
}

.ActivityReviewProgressIndicator .ActivityReviewStatusContainer > p {
  color: var(--color-grey);
  overflow: show;
  white-space: nowrap;
}

.ActivityReviewProgressIndicator .ActivityReviewStatusContainer .ActivityReviewStatusBar {
  display: flex;
  flex-direction: row;
  height: 12px;
  margin: var(--spacing-s) 0;
}

.ActivityReviewProgressIndicator
  .ActivityReviewStatusContainer
  .ActivityReviewStatusBar
  .ActivityReviewStatusBarFilled {
  background-color: var(--color-green);
}

.ActivityReviewProgressIndicator
  .ActivityReviewStatusContainer
  .ActivityReviewStatusBar
  .ActivityReviewStatusBarUnfilled {
  background-color: var(--color-green-20);
}

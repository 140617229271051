.AuditChangesPanelContent .ChangedField {
  position: relative;
  overflow-wrap: break-word;
}
.AuditChangesPanelContent .ChangedField .PreviousVersion {
  color: var(--color-grey-60);
  margin-right: var(--spacing-ml);
}

.AuditChangesPanelContent .ChangedField .NewVersion {
  margin-left: var(--spacing-ml);
}
.AuditChangesPanelContent .ChangedField .NewVersion.Light {
  color: var(--color-grey-80);
}

.AuditChangesPanelContent .ChangedField svg {
  position: absolute;
  left: 48%;
  top: 28px;
}

.AuditChangesPanelContent .ChangedField svg path {
  stroke: var(--color-grey);
}

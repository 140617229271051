.ProjectsSearchContentHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ProjectsSearchContentHeaderSortingContainer {
  display: flex;
  align-items: center;
}

.ProjectsSearchContentHeaderSortingContainer > span {
  font-weight: 700;
}

.ProjectsSearchContentHeaderSortingContainerSelect .Select {
  margin: 0 !important;
}

.ProjectsSearchContentHeaderSortingContainerSelect .Select-selector {
  height: auto !important;
  border: none !important;
  background-color: transparent !important;
  padding-left: var(--spacing-xs) !important;
}

.ProjectsSearchContentHeaderSortingContainerSelect .Select-selection-item {
  line-height: normal !important;
  font-size: var(--font-size-body2) !important;
}

.ProjectsSearchContentHeaderSortingContainerSelect input {
  height: auto !important;
  line-height: normal !important;
}

.ProjectsSearchContentHeaderSortingContainerSelect .Select-arrow {
  right: 0;
}

.ProjectsSearchContentProjectCard {
  cursor: pointer;
}

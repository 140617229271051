.PublicProjectTemplateComponent .PublicProjectHeader_Details {
  min-height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.PublicProjectTemplateComponent .TemplateTabs {
  background-color: var(--color-light-grey);
}

.PublicProjectTemplateComponent .TemplateTabs.TabsContent {
  padding: 0;
}

.PublicProjectTemplateComponent .TemplateTabs.TabsContent .PublicProjectTabSection {
  background-color: var(--color-white);
  padding: var(--spacing-2xl);
  margin-bottom: var(--spacing-ml);
  box-shadow: 0 0 var(--box-shadow-spread) var(--color-box-shadow);
}

.PublicProjectTemplateComponent .PublicProjectHeader_Details > div {
  display: flex;
  align-items: center;
  gap: var(--spacing-m);
}

.PublicProjectTemplateComponent .PublicProjectHeader_Details > div > span {
  margin-left: var(--spacing-m);
}

.PublicProjectTemplateComponent .PublicProjectHeader_DeveloperDetails {
  display: flex;
  align-items: center;
}

.PublicProjectTemplateComponent .PublicProjectHeader_DeveloperDetails.ColumnGapSmall {
  gap: var(--spacing-sl);
}
.PublicProjectTemplateComponent .PublicProjectHeader_DeveloperDetails.ColumnGapMedium {
  gap: var(--spacing-ml);
}

.PublicProjectTemplateComponent .PublicProjectHeader_DeveloperDetails .VerticalLineLightGrey {
  width: 1px;
  height: var(--spacing-ml);
  background-color: var(--color-grey-20);
}

.PublicProjectTemplateComponent .PublicProjectHeader_DeveloperDetails a,
.PublicProjectTemplateComponent .PublicProjectHeader_DeveloperDetails > .BadgeInfo,
.PublicProjectTemplateComponent .PublicProjectHeader_DeveloperDetails > .DeveloperInfo {
  display: flex;
  align-items: center;
  gap: var(--spacing-s);
  color: var(--color-grey);
}

.PublicProjectTemplateComponent .PublicProjectHeader_DeveloperDetails .BadgeInfo img {
  height: var(--spacing-ml);
  margin-left: var(--spacing-xs);
}

.PublicProjectTemplateComponent .PublicProjectHeader_DeveloperDetails .BadgeInfo h3 {
  margin-right: var(--spacing-xs);
}

.PublicProjectTemplateComponent .PublicProjectHeader > button {
  padding-left: 0;
}

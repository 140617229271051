.ExpandableCardHeader {
  padding-bottom: var(--spacing-ml);
}

.ExpandableCardHeader div {
  display: flex;
  gap: var(--spacing-xs);
}

.ExpandableCardHeaderImageButton {
  display: flex;
  align-items: flex-end;
}

.ExpandableCardHeader h3 {
  margin-bottom: 0;
}

.ExpandableCardContent {
  box-sizing: border-box;
  height: calc(100% - (var(--spacing-ml) + var(--spacing-ml)));
}

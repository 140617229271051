.VcuPiuContainer {
  padding: var(--spacing-m) var(--spacing-m);
  background-color: var(--color-white);
  color: var(--color-grey);

  height: 136px;
  width: 100%;
  display: flex;
  flex: 1;
  align-items: flex-start;
}

.VcuPiuContainer .VcuPiuGraph {
  display: flex;
  flex-direction: column;
  flex: 3;
  height: 104px;
  row-gap: var(--spacing-xxs);
  margin-right: var(--spacing-m);
}

.VcuPiuContainer .VcuPiuGraph .PiuGraphRowFilled {
  flex: 1;
  background-color: var(--color-green-20);
}

.VcuPiuContainer .VcuPiuGraph .VcuGraphRowFilled {
  flex: 1;
  background-color: var(--color-green);
}

.VcuPiuContainer .VcuPiuGraph .VcuGraphRowEmpty {
  height: 2px;
  background-color: var(--color-green);
}

.VcuPiuContainer .VcuPiuHeaderArea {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 7;
}

.VcuPiuContainer .VcuPiuHeaderArea > h2 {
  margin-bottom: var(--spacing-s);
}

.VcuPiuContainer .VcuPiuHeaderArea p.body3 {
  color: var(--color-grey-40);
  margin-top: auto;
}

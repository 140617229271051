.ProjectProcessSection {
  height: fit-content;
  padding: var(--spacing-2xl) var(--spacing-xl) var(--spacing-xl) var(--spacing-2xl);
  background-color: var(--color-white);
  display: flex;
  flex-direction: column;
}

.ProjectProcessSection h2 {
  margin-bottom: var(--spacing-ml);
}

.ProjectProcessSection p {
  white-space: pre-line;
  margin-bottom: var(--spacing-m);
}

.ProjectProcessSection > img {
  width: max-content;
  height: max-content;
  margin: 0 auto;
  max-width: 100%;
}

.ProjectProcessSection a {
  text-decoration: underline;
  color: var(--color-blue);
  margin-bottom: var(--spacing-m);
}

.StandardSpecificSectionDivider {
  margin: var(--spacing-2xl) 0;
}

.StandardSpecificSectionStepContainer {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-ll);
}

.StandardSpecificSectionStepContainer .ChartLegend {
  font-size: var(--font-size-body2);
}

.StandardSpecificButtonContainer {
  display: flex;
  margin-top: var(--spacing-2xl);
  gap: var(--spacing-m);
}

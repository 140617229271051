.ChartsSection {
  padding: var(--spacing-2xl) var(--spacing-4xl) var(--spacing-4xl) var(--spacing-2xl);
  background-color: var(--color-white);
}

.ChartsSection .body1 {
  white-space: pre-line;
}

.ChartsSection h3 {
  margin-bottom: var(--spacing-ml);
}
